import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.min.css';
import logoImage from '../../assets/images/pinn_background.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import '../../components/AlertStack.css';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [domainid, setDomainId] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Login | PinnSystem';
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post('/api/login', {
        email, // Enviando 'email' ao invés de 'username'
        password,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('email', email);
        localStorage.setItem('domain_id', domainid); // Certifique-se de que isso é necessário
        navigate(`/home/dashboard`);
      }
    } catch (error) {
      console.error('Login error:', error); // Log error for debugging
      const errorMessage = (error.response && error.response.data && typeof error.response.data.detail === 'string')
        ? error.response.data.detail
        : 'Ocorreu um erro. Por favor, tente novamente.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="login-container">
      {/* Exibindo o alert de erro, se houver */}
      {error && (
        <div className="alert-container">
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">{error}</Alert>
          </Stack>
        </div>
      )}
      <div className="left-side"></div>
      <div className="right-side">
        <img src={logoImage} alt="PinnSystem" className="login-logo" />
        <h2>Bem-vindo de volta! 👋</h2>
        <p>Por favor, faça login na sua conta</p>
        <form className="login-form" onSubmit={handleSubmit}>
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            name="email"
            className="email-input"
            placeholder="Digite seu e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />


          <label htmlFor="password">Senha</label>
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span onClick={togglePasswordVisibility} className="password-toggle-icon">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Entrando...' : 'Entrar'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
