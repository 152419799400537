import React from 'react';
import axios from 'axios';

const DeleteAsset = ({ asset, onClose, onDelete }) => {
    const handleDelete = () => {
        axios.delete(`/api/assets/${asset.id}`)
            .then(response => {
                onDelete(asset.id);
                onClose();
            })
            .catch(error => console.error('Error deleting asset: ', error));
    };

    return (
        <div className="delete-asset-modal">
            <h2>Deletar Ativo</h2>
            <p>Você tem certeza que deseja deletar {asset.nome_ativo}?</p>
            <button onClick={handleDelete}>Deletar</button>
            <button onClick={onClose}>Cancelar</button>
        </div>
    );
};

export default DeleteAsset;
