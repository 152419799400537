import React, { useState } from 'react';
import axios from 'axios';
import './Styles.min.css';
import logoImage from '../../assets/images/pinn_background.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const RegisterDomain = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [department, setDepartment] = useState('');
  const [company, setCompany] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const domainResponse = await axios.get(`/api/domain-exists/${company}`);
      
      if (domainResponse.data.exists) {
        // Se o domínio já existe, registra o usuário
        await axios.post('/api/register-user/', {
          email,
          password,
          username,
          department,
          domain_name: company
        });
        setSuccess('Usuário registrado com sucesso!');
      } else {
        // Se o domínio não existe, cria o domínio e registra o usuário
        await axios.post('/api/register-domain/', { name: company });
        await axios.post('/api/register-user/', {
          email,
          password,
          username,
          department,
          domain_name: company
        });
        setSuccess('Usuário e domínio registrados com sucesso!');
      }
      setError('');

      // Redireciona para a página de login após o sucesso
      navigate('/auth/login');
    } catch (error) {
      setError('Erro ao registrar: ' + (error.response ? error.response.data.detail : error.message));
      setSuccess('');
    }
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Novo Usuário | PinnSystem</title>
      </Helmet>
      <div className="left-side"></div>
      <div className="right-side">
        <img src={logoImage} alt="PinnSystem" className="logo" />
        <h2>É um prazer ter você aqui! 🐦</h2>
        <p>Por favor, cadastre as seguintes informações</p>
        <form className="login-form" onSubmit={handleRegister}>
          <label htmlFor="email">E-mail Profissional</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Digite seu e-mail"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label htmlFor="username">Nome de Usuário</label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Digite seu nome de usuário"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label htmlFor="department">Departamento</label>
          <input
            type="text"
            id="department"
            name="department"
            placeholder="Digite seu departamento"
            required
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          />

          <label htmlFor="password">Senha</label>
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Digite sua senha"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={togglePasswordVisibility} className="password-toggle-icon">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          <label htmlFor="company">Nome da Empresa (Domínio)</label>
          <input
            type="text"
            id="company"
            name="company"
            placeholder="Digite o nome da sua empresa"
            required
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />

          <button type="submit" className="login-button">Cadastrar-se</button>
        </form>
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
      </div>
    </div>
  );
};

export default RegisterDomain;
