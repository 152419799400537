import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Existing translations
      term: 'Use Term',
      no_notifications: 'No notifications',
      Distribuição_de_Utilização_de_Ativos: 'Asset Usage Distribution',
      profile: 'My Profile',
      cost_asset: 'Total Spending on Technological Assets',
      settings: 'Settings',
      roi: 'Total investment over assets',
      payment: 'Payment',
      referral: 'Referral',
      home: 'Home',
      asset_management: 'Asset Management',
      asset_name: 'Asset Name',
      register_asset: 'Register Asset',
      view_asset: 'View Asset',
      security: 'Security',
      logout: 'Logout',
      dashboard: 'Dashboard',
      company_logo: 'Company Logo',
      // New translations for ComputerForm
      computer_form_title: 'Asset Registration',
      computer_info: 'Computer Information',
      computer_brand: 'Computer Brand',
      computer_model: 'Model',
      serial_number: 'Serial Number',
      technical_specs: 'Technical Specifications',
      computer_status: 'Computer Status',
      active: 'Active',
      in_use: 'In Use',
      under_maintenance: 'Under Maintenance',
      inactive: 'Inactive',
      responsible: 'Responsible for the Asset',
      has_warranty: 'This computer has a warranty?',
      warranty_code: 'Warranty Code',
      warranty_expiry: 'Warranty Expiry Date',
      has_antivirus: 'This computer has antivirus?',
      notes: 'Notes',
      register_computer: 'Register Computer',
      registering: 'Registering...',
      success_message: 'Asset successfully registered!',
      error_message: 'Error registering computer: ',
      // Add more translations as needed
      // Translations for Dashboard
      active_for: 'Active for',
      hours: 'hours',
      minutes: 'minutes',
      almost_due: 'Almost Due',
      total_assets: 'Total Assets',
      assets_in_use: 'Assets in Use',
      assets_in_maintenance: 'Assets in Maintenance',
      available_assets: 'Available Assets',
      trends_and_forecasts: 'Trends and Forecasts',
      current_usage: 'Current Usage',
      projected_usage: 'Projected Usage',
      asset_name: 'Asset Name',
      asset_type: 'Asset Type',
      manufacturer: 'Manufacturer',
      model: 'Model',
      serial_number: 'Serial Number',
      reg_model: 'Reg. Model',
      technical_specs: 'Technical Specifications',
      has_warranty: 'Has Warranty',
      warranty_details: 'Warranty Details',
      warranty_expiry: 'Warranty Expiry Date',
      status: 'Status',
      responsible: 'Responsible',
      location: 'Location',
      delivery_date: 'Delivery Date',
      has_antivirus: 'Has Antivirus',
      notes: 'Notes',
      edit: 'Edit',
      archive: 'Archive',
      delete: 'Delete',
      search_placeholder: 'Search...',
      download: 'Download',
      asset_management: 'Asset Management',
      asset_management_title: 'Asset Management',
      download_warning: 'This functionality is not available at the moment, but it will be soon.',
      search_placeholder: "Search assets...",
      asset_status_available: "Available",
      asset_status_in_use: "In use",
      asset_status_in_maintenance: "In maintenance"
    },
  },
  pt: {
    translation: {
      // Existing translations
      term: 'Termo de uso de ativos',
      no_notifications: 'Sem notificações',
      Distribuição_de_Utilização_de_Ativos: 'Distribuição de utilização de Ativos',
      profile: 'Meu perfil',
      cost_asset: 'Total de Gastos com Ativos Tecnológicos',
      settings: 'Configurações',
      payment: 'Pagamento',
      roi: 'Investimento total sobre os ativos',
      referral: 'Indicação',
      home: 'Início',
      asset_management: 'Gestão de Ativos',
      asset_name: 'Nome do Ativo',
      register_asset: 'Cadastrar Ativo',
      view_asset: 'Visualizar Ativo',
      security: 'Segurança',
      logout: 'Sair',
      dashboard: 'Dashboard',
      company_logo: 'Logo da Empresa',
      // New translations for ComputerForm
      computer_form_title: 'Cadastro de Ativos',
      computer_info: 'Informações do Computador',
      computer_brand: 'Marca do computador',
      computer_model: 'Modelo',
      serial_number: 'Número de série',
      technical_specs: 'Especificações técnicas',
      computer_status: 'Status do computador',
      active: 'Ativo',
      in_use: 'Em uso',
      under_maintenance: 'Em manutenção',
      inactive: 'Parado',
      responsible: 'Responsável pelo ativo',
      has_warranty: 'Este computador possui garantia?',
      warranty_code: 'Código da garantia',
      warranty_expiry: 'Data de expiração da garantia',
      has_antivirus: 'Este computador possui antivírus?',
      notes: 'Observações',
      register_computer: 'Registrar Computador',
      registering: 'Cadastrando...',
      success_message: 'Ativo cadastrado com sucesso!',
      error_message: 'Erro ao cadastrar Ativo: Esse Ativo já esta cadastrado ',
      // Add more translations as needed
      // Translations for Dashboard
      active_for: 'Ativo por',
      hours: 'horas',
      minutes: 'minutos',
      almost_due: 'Quase vencido',
      total_assets: 'Total de Ativos',
      assets_in_use: 'Ativos em Uso',
      assets_in_maintenance: 'Ativos em Manutenção',
      available_assets: 'Ativos Disponíveis',
      trends_and_forecasts: 'Tendências e Previsões',
      current_usage: 'Uso Atual',
      projected_usage: 'Uso Projetado',
      asset_name: 'Nome do Ativo',
      asset_type: 'Tipo do Ativo',
      manufacturer: 'Fabricante',
      model: 'Modelo',
      serial_number: 'Número de Série',
      reg_model: 'Reg. Model',
      technical_specs: 'Especificações Técnicas',
      has_warranty: 'Possui Garantia',
      warranty_details: 'Detalhes da Garantia',
      warranty_expiry: 'Data Expiração Garantia',
      status: 'Status',
      responsible: 'Responsável',
      location: 'Localidade',
      delivery_date: 'Data Entrega Responsável',
      has_antivirus: 'Possui Antivirus',
      notes: 'Observações',
      edit: 'Editar',
      archive: 'Arquivar',
      delete: 'Deletar',
      search_placeholder: 'Pesquisar...',
      download: 'Download',
      asset_management: 'Gerenciamento de Ativos',
      asset_management_title: 'Gerenciamento de Ativos',
      download_warning: 'Esta funcionalidade não está disponível no momento, mas estará em breve.',
      search_placeholder: "Pesquisar ativos...",
      asset_status_available: "Disponível",
      asset_status_in_use: "Em uso",
      asset_status_in_maintenance: "Em manutenção",
    },
  },
  es: {
    translation: {
      // Existing translations
      term: 'Soporte',
      Distribuição_de_Utilização_de_Ativos: 'Distribución del uso de activos',
      no_notifications: 'Sin notificaciones',
      cost_asset: 'Gasto Total en Activos Tecnológicos',
      profile: 'Mi perfil',
      roi: 'Inversión total sobre activos',
      settings: 'Configuraciones',
      payment: 'Pago',
      referral: 'Recomendación',
      home: 'Inicio',
      asset_management: 'Gestión de Activos',
      register_asset: 'Registrar Activo',
      view_asset: 'Ver Activo',
      security: 'Seguridad',
      logout: 'Salir',
      dashboard: 'Dashboard',
      company_logo: 'Logo de la Empresa',
      // New translations for ComputerForm
      computer_form_title: 'Registro de Activos',
      computer_info: 'Información del Computador',
      computer_info: 'Información del Computador',
      computer_brand: 'Marca del computador',
      computer_model: 'Modelo',
      serial_number: 'Número de serie',
      technical_specs: 'Especificaciones técnicas',
      computer_status: 'Estado del computador',
      active: 'Activo',
      in_use: 'En uso',
      under_maintenance: 'En mantenimiento',
      inactive: 'Inactivo',
      responsible: 'Responsable del activo',
      has_warranty: '¿Este computador tiene garantía?',
      warranty_code: 'Código de garantía',
      warranty_expiry: 'Fecha de vencimiento de la garantía',
      has_antivirus: '¿Este computador tiene antivirus?',
      notes: 'Notas',
      register_computer: 'Registrar Computador',
      registering: 'Registrando...',
      success_message: '¡Computador registrado con éxito!',
      error_message: 'Error al registrar computador: ',
      // Add more translations as needed
      // Translations for Dashboard
      active_for: 'Activo por',
      hours: 'horas',
      minutes: 'minutos',
      almost_due: 'Casi vencido',
      total_assets: 'Total de Activos',
      assets_in_use: 'Activos en Uso',
      assets_in_maintenance: 'Activos en Mantenimiento',
      available_assets: 'Activos Disponibles',
      trends_and_forecasts: 'Tendencias y Pronósticos',
      current_usage: 'Uso Actual',
      projected_usage: 'Uso Proyectado',
      asset_name: 'Nombre del Activo',
      asset_type_impressora: 'Tipo del Activo',
      manufacturer: 'Fabricante',
      model: 'Modelo',
      serial_number: 'Número de Serie',
      reg_model: 'Reg. Model',
      technical_specs: 'Especificaciones Técnicas',
      has_warranty: 'Tiene Garantía',
      warranty_details: 'Detalles de la Garantía',
      warranty_expiry: 'Fecha de Expiración de la Garantía',
      status: 'Estado',
      responsible: 'Responsable',
      location: 'Ubicación',
      delivery_date: 'Fecha de Entrega',
      has_antivirus: 'Tiene Antivirus',
      notes: 'Notas',
      edit: 'Editar',
      archive: 'Archivar',
      delete: 'Eliminar',
      search_placeholder: 'Buscar...',
      download: 'Descargar',
      asset_management: 'Gestión de Activos',
      asset_management_title: 'Gestión de Activos',
      download_warning: 'Esta funcionalidad no está disponible en este momento, pero lo estará pronto.',
      search_placeholder: "Buscar activos...",
      asset_status_available: "Disponible",
      asset_status_in_use: "En uso",
      asset_status_in_maintenance: "En mantenimiento",
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt', // default language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
