import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';

import { Box, Grid, Card, CardContent, Typography, Alert } from '@mui/material';
import ComputerIcon from '@mui/icons-material/Computer';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, Tooltip, Legend } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { format, subMonths } from 'date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const { t } = useTranslation();
    const [error, setError] = useState(null);
    const [totalAssets, setTotalAssets] = useState(0);
    const [assetsInUse, setAssetsInUse] = useState(0);
    const [assetsInMaintenance, setAssetsInMaintenance] = useState(0);
    const [availableAssets, setAvailableAssets] = useState(0);
    const [trendData, setTrendData] = useState({ total_assets: 0, available_assets: 0 });

    useEffect(() => {
        document.title = `${t('dashboard')} | PinnSystem`;

        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error("Token not found");
                }
                const headers = {
                    Authorization: `Bearer ${token}`
                };
                const response = await axios.get('/api/dashboard/', { headers });
                setTotalAssets(response.data.total_assets);
                setAssetsInUse(response.data.assets_in_use);
                setAssetsInMaintenance(response.data.assets_in_maintenance);
                setAvailableAssets(response.data.available_assets);
                const trendResponse = await axios.get('/api/computer-trends', { headers });

                setTrendData(trendResponse.data);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError(err);
            }
        };

        fetchData();
    }, [t]);

    if (error) {
        return <Alert severity="error">Error: {error.message}</Alert>;
    }

    // Função para gerar os últimos n meses
    const generateLastMonths = (n) => {
        let months = [];
        const today = new Date();
        for (let i = 0; i < n; i++) {
            months.push(format(subMonths(today, i), 'MMMM')); // Nome do mês
        }
        return months.reverse(); // Inverter para que os meses sejam mostrados na ordem correta
    };

    const labels = generateLastMonths(4); // Exibe os últimos 6 meses

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
                <Box component="span" sx={{ color: 'gray' }}>{t('home')}/</Box>
                <Box component="span">{t('dashboard')}</Box>
            </Typography>
            <Grid container spacing={2}>
                {/* Updated Cards with Modern UI */}
                <Grid item xs={12} md={3}>
                    <Card
                        sx={{
                            background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
                            color: '#fff',
                            height: '100%',
                            borderRadius: '16px',
                            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.3s',
                            '&:hover': {
                                transform: 'translateY(-10px)',
                                boxShadow: '0 8px 30px 0 rgba(0, 0, 0, 0.2)',
                            },
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{t('total_assets')}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <ComputerIcon sx={{ fontSize: 40, marginRight: 2 }} />
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{totalAssets}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Card
                        sx={{
                            background: 'linear-gradient(135deg, #ff7e5f 0%, #feb47b 100%)',
                            color: '#fff',
                            height: '100%',
                            borderRadius: '16px',
                            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.3s',
                            '&:hover': {
                                transform: 'translateY(-10px)',
                                boxShadow: '0 8px 30px 0 rgba(0, 0, 0, 0.2)',
                            },
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{t('assets_in_use')}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <ComputerIcon sx={{ fontSize: 40, marginRight: 2 }} />
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{assetsInUse}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Card
                        sx={{
                            background: 'linear-gradient(135deg, #ff512f 0%, #dd2476 100%)',
                            color: '#fff',
                            height: '100%',
                            borderRadius: '16px',
                            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.3s',
                            '&:hover': {
                                transform: 'translateY(-10px)',
                                boxShadow: '0 8px 30px 0 rgba(0, 0, 0, 0.2)',
                            },
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{t('assets_in_maintenance')}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <ComputerIcon sx={{ fontSize: 40, marginRight: 2 }} />
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{assetsInMaintenance}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Card
                        sx={{
                            background: 'linear-gradient(135deg, #11998e 0%, #38ef7d 100%)',
                            color: '#fff',
                            height: '100%',
                            borderRadius: '16px',
                            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.3s',
                            '&:hover': {
                                transform: 'translateY(-10px)',
                                boxShadow: '0 8px 30px 0 rgba(0, 0, 0, 0.2)',
                            },
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{t('available_assets')}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                                <ComputerIcon sx={{ fontSize: 40, marginRight: 2 }} />
                                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{availableAssets}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ backgroundColor: '#fff', color: '#000', height: '100%' }}>
                        <CardContent>
                            <Typography variant="h5">{t('Distribuição_de_Utilização_de_Ativos')}</Typography>
                            <Line
                                data={{
                                    labels: labels,
                                    datasets: [
                                        {
                                            label: 'Total Assets',
                                            data: [trendData.total_assets, trendData.available_assets, totalAssets],
                                            borderColor: 'rgba(75, 192, 192, 1)',
                                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                                            fill: false,
                                            tension: 0.4,
                                        },
                                        {
                                            label: 'Available Assets',
                                            data: [trendData.total_assets, trendData.available_assets, availableAssets],
                                            borderColor: 'rgba(255, 99, 132, 1)',
                                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                            fill: false,
                                            tension: 0.4,
                                        },
                                    ],
                                }}
                                options={{
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            title: {
                                                display: true,
                                                text: 'Number of Assets',
                                            },
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                    },
                                    animation: {
                                        duration: 1000,
                                        easing: 'easeInOutQuad',
                                    },
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={{ backgroundColor: '#fff', color: '#000', height: '100%' }}>
                        <CardContent>
                            <Typography variant="h5">{t('cost_asset')}</Typography>
                            <Pie
                                data={{
                                    labels: [t('Ativos Disponíveis'), t('Ativos em Uso')],
                                    datasets: [
                                        {
                                            data: [trendData.available_assets, assetsInUse],
                                            backgroundColor: ['rgba(75, 192, 255, 0.4)', 'rgba(75, 192, 192, 0.8)'],
                                            borderColor: ['rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        tooltip: {
                                            callbacks: {
                                                label: function (tooltipItem) {
                                                    return `${tooltipItem.label}: ${tooltipItem.raw}`;
                                                },
                                            },
                                        },
                                    },
                                }}
                            />

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
