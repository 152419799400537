import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import './styles.min.css';

const Profile = () => {
    const [user, setUser] = useState({});
    const [profilePicture, setProfilePicture] = useState(null);
    const [newUsername, setNewUsername] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('');

    useEffect(() => {
        document.title = 'Perfil | PinnSystem';

        const token = localStorage.getItem('token');
        axios.get('https://app.pinnsystem.com/api/user/profile', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                setUser(response.data);
                setProfilePicture(response.data.profile_picture);
                setNewUsername(response.data.username);
            })
            .catch(error => {
                console.error('There was an error fetching the user profile!', error);
            });
    }, []);

    useEffect(() => {
        if (alertMessage) {
            const timer = setTimeout(() => {
                setAlertMessage('');
                setAlertSeverity('');
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [alertMessage]);

    const handleProfilePictureChange = async (event) => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://app.pinnsystem.com/api/upload_profile_picture/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            setProfilePicture(response.data.file_path);
            setAlertMessage('Foto de perfil atualizada com sucesso!');
            setAlertSeverity('success');
        } catch (error) {
            console.error('Erro ao atualizar a foto de perfil', error);
            setAlertMessage('Erro ao atualizar a foto de perfil');
            setAlertSeverity('error');
        }
    };

    const handleUsernameChange = (event) => {
        setNewUsername(event.target.value);
    };

    const handleUsernameUpdate = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put('https://app.pinnsystem.com/api/update_username/', { new_username: newUsername }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUser(prevUser => ({ ...prevUser, username: newUsername }));
            setAlertMessage('Nome de usuário atualizado com sucesso!');
            setAlertSeverity('success');
        } catch (error) {
            console.error('Erro ao atualizar nome de usuário', error);
            setAlertMessage('Erro ao atualizar nome de usuário');
            setAlertSeverity('error');
        }
    };

    return (
        <div className="profile-container">
            <h1>Bem-vindo ao controle do seu Sistema!</h1>
            <div className="profile-picture-container">
                {profilePicture ? (
                    <img
                        src={`https://app.pinnsystem.com/api/${profilePicture}`}
                        alt="Profile"
                        className="profile-picture"
                    />
                ) : (
                    <AccountCircleIcon className="default-profile-picture" />
                )}
                <label htmlFor="profilePicture" className="edit-button">
                    Editar Foto
                </label>
                <input
                    id="profilePicture"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleProfilePictureChange}
                />
            </div>
            <div className="inde-edit-profile-container">
                <input
                    type="text"
                    value={newUsername}
                    onChange={handleUsernameChange}
                    placeholder="Nome do Usuário"
                    className="username-input"
                />
                <button onClick={handleUsernameUpdate} className="update-button">
                    Atualizar Nome de Usuário
                </button>
            </div>
            <div className="admin-label-container">
                <label className="admin-label">Admin</label>
            </div>
            {alertMessage && (
                <Stack sx={{ width: '100%', marginTop: 2 }} spacing={2}>
                    <Alert severity={alertSeverity}>{alertMessage}</Alert>
                </Stack>
            )}
        </div>
    );
};

export default Profile;
