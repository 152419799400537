import React from 'react';
import { Link } from 'react-router-dom';
import './NetworkError.css'; 

const NetworkError = () => {
  return (
    <div className="network-error-container">
      <h1 className="error-heading">Network Error</h1>
      <p className="error-message">Ocorreu um problema na conexão com o servidor. Por favor verifique sua conexão com a internet e tente novamente.</p>
      <Link to="/auth/login" className="login-link">Ir para o Login</Link>
    </div>
  );
};

export default NetworkError;
