import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RegisterDomain from './components/operator/RegisterDomain';
import LoginForm from './components/login/LoginForm';
import Dashboard from './components/main/Dashboard';
import ComputerForm from './components/ativos/index'; 
import AssetList from './components/Assets_Tables';
import PrivateRoute from './components/PrivateRoute';
import MainLayout from './components/MainLayout';
import AxiosInterceptor from './components/AxiosInterceptor';
import NetworkError from './components/NetworkError';
import Profile from './components/Controle/index';  
import InterApp from './components/Integrations/index';
import PageNotFound from './components/PageNotFound';  

const App = () => {
  return (
    <Router>
      <AxiosInterceptor>
        <Routes>
          <Route path="/" element={<Navigate to="/auth/login" />} />
          
          <Route path="/auth/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterDomain />} />
          <Route path="/home/dashboard" element={
            <PrivateRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/cadastro/ativos" element={
            <PrivateRoute>
              <MainLayout>
                <ComputerForm />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/gerenciamento/ativos" element={
            <PrivateRoute>
              <MainLayout>
                <AssetList />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/controle/perfil" element={
            <PrivateRoute>
              <MainLayout>
                <Profile />
              </MainLayout>
            </PrivateRoute>
          } /> 
          <Route path="/integracoes/API" element={
            <PrivateRoute>
              <MainLayout>
                <InterApp />
              </MainLayout>
            </PrivateRoute>
          } /> 
          <Route path="/network-error" element={<NetworkError />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AxiosInterceptor>
    </Router>
  );
};

export default App;
