import React, { useState } from 'react';
import { FaGoogleDrive, FaTrello, FaJira } from 'react-icons/fa';
import './styles.min.css';

function InterApp() {
  const [showDriveModal, setShowDriveModal] = useState(false);
  const [showTicketBox, setShowTicketBox] = useState('');

  const openDriveModal = () => {
    setShowDriveModal(true);
  };

  const closeDriveModal = () => {
    setShowDriveModal(false);
  };

  const openTicketBox = (type) => {
    setShowTicketBox(type);
  };

  return (
    <div className="app-container">
      <h1>Integrações PinnSystem</h1>
      <div className="icons-container">
        {/* Google Drive Icon */}
        <div className="icon" onClick={openDriveModal}>
          <FaGoogleDrive size={80} />
          <p>Google Drive</p>
        </div>

        {/* Trello Icon */}
        <div className="icon" onClick={() => openTicketBox('trello')}>
          <FaTrello size={80} />
          <p>Trello</p>
        </div>

        {/* Jira Icon */}
        <div className="icon" onClick={() => openTicketBox('jira')}>
          <FaJira size={80} />
          <p>Jira</p>
        </div>
      </div>

      {/* Google Drive Modal */}
      {showDriveModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Salvar no Google Drive</h2>
            <p>Você quer salvar as informações PinnSystem em seu Drive?</p>
            <button onClick={closeDriveModal}>Sim</button>
            <button onClick={closeDriveModal}>Não</button>
          </div>
        </div>
      )}

      {/* Ticket Box */}
      {showTicketBox === 'trello' && (
        <div className="ticket-box">
          <h2>Abrir chamado no Trello</h2>
          <p>Preencha as informações do chamado no Trello.</p>
        </div>
      )}

      {showTicketBox === 'jira' && (
        <div className="ticket-box">
          <h2>Abrir chamado no Jira</h2>
          <p>Preencha as informações do chamado no Jira.</p>
        </div>
      )}
    </div>
  );
}

export default InterApp;
