import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { Alert, Stack } from '@mui/material';
import './EditAssetModal.css';
import { imageUrls } from '../ativos/Data_Ativo';

Modal.setAppElement('#root');

const EditAssetModal = ({ asset, onClose, onSave }) => {
    const [formData, setFormData] = useState(asset);
    const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });

    const getAssetImage = (modelo) => {
        return imageUrls[modelo] || 'https://via.placeholder.com/150';
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const updateAsset = async (updatedAsset) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(`/api/assets/${updatedAsset.id}`, updatedAsset, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Ativo atualizado com sucesso:', response.data);
            setAlert({ open: true, severity: 'success', message: 'Ativo atualizado com sucesso!' });
            onSave(response.data);
            onClose();
        } catch (error) {
            console.error('Erro ao atualizar o ativo:', error);
            setAlert({ open: true, severity: 'error', message: 'Erro ao atualizar o ativo.' });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateAsset(formData);
    };

    const isNotebook = formData.tipo_ativo.toLowerCase() === 'notebook';
    const isInUse = formData.status.toLowerCase() === 'em uso';

    return (
        <Modal isOpen={true} onRequestClose={onClose} contentLabel="Edit Asset" className="edit-asset-modal">
            <h2 className="modal-title">Informações do Ativo</h2>
            <div className="asset-modal-content">
                {/* Exibir alert se necessário */}
                {alert.open && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity={alert.severity}>{alert.message}</Alert>
                    </Stack>
                )}

                {/* Imagem do ativo */}
                <div className="asset-image-container">
                    <img src={getAssetImage(formData.modelo)} alt={formData.modelo} className="smart-asset-image" />
                </div>

                {/* Detalhes do ativo */}
                <form className="asset-form" onSubmit={handleSubmit}>
                    {/* Campos comuns para todos os tipos de ativos */}
                    <div className="asset-detail">
                        <label>Nome do ativo</label>
                        <input type="text" name="nome_ativo" value={formData.nome_ativo} onChange={handleChange} />
                    </div>

                    <div className="asset-detail">
                        <label>Data de aquisição</label>
                        <input type="date" name="data_aquisicao" value={formData.data_aquisicao} onChange={handleChange} />
                    </div>

                    <div className="asset-detail">
                        <label>Fabricante</label>
                        <input type="text" name="fabricante" value={formData.fabricante} onChange={handleChange} />
                    </div>

                    <div className="asset-detail">
                        <label>Modelo</label>
                        <input type="text" name="modelo" value={formData.modelo} onChange={handleChange} />
                    </div>

                    <div className="asset-detail">
                        <label>Número de Série</label>
                        <input type="text" name="numero_serie" value={formData.numero_serie} onChange={handleChange} />
                    </div>

                    <div className="asset-detail">
                        <label>Especificações Técnicas</label>
                        <textarea name="especificacoes_tecnicas" value={formData.especificacoes_tecnicas} onChange={handleChange} />
                    </div>

                    <div className="asset-detail">
                        <label>Valor do ativo</label>
                        <input type="text" name="valor_ativo" value={formData.valor_ativo} onChange={handleChange} />
                    </div>

                    {/* Campos específicos apenas para notebooks */}
                    {isNotebook && (
                        <>
                            <div className="asset-detail">
                                <label>Sistema Operacional</label>
                                <select name="sistema_operacional" value={formData.sistema_operacional} onChange={handleChange}>
                                    <option value="Windows">Windows</option>
                                    <option value="Linux">Linux</option>
                                    <option value="macOS">macOS</option>
                                </select>
                            </div>

                            <div className="asset-detail">
                                <label>Reg Model</label>
                                <input type="text" name="reg_model" value={formData.reg_model} onChange={handleChange} />
                            </div>

                            <div className="asset-detail">
                                <label className="checkbox-container">
                                    Possui Antivirus
                                    <input
                                        type="checkbox"
                                        name="possui_antivirus"
                                        checked={formData.possui_antivirus}
                                        onChange={handleChange}
                                    />
                                    <span className="custom-checkbox"></span>
                                </label>
                            </div>

                        </>
                    )}

                    <div className="asset-detail">
                        <label>Status do ativo</label>
                        <select name="status" value={formData.status} onChange={handleChange}>
                            <option value="disponivel">Disponivel</option>
                            <option value="em uso">Em uso</option>
                            <option value="em manutencao">Em manutenção</option>
                        </select>
                    </div>

                    {/* Campos que aparecem somente se o ativo estiver "Em uso" */}
                    {isInUse && (
                        <>
                            <div className="asset-detail">
                                <label>Responsável</label>
                                <input type="text" name="responsavel" value={formData.responsavel} onChange={handleChange} />
                            </div>

                            <div className="asset-detail">
                                <label>Data de Entrega</label>
                                <input type="date" name="data_entrega_responsavel" value={formData.data_entrega_responsavel} onChange={handleChange} />
                            </div>
                        </>
                    )}

                    {/* Campos comuns para todos os ativos */}
                    <div className="asset-detail">
                        <label className="checkbox-container">
                            Possui Garantia
                            <input
                                type="checkbox"
                                name="possui_garantia"
                                checked={formData.possui_garantia}
                                onChange={handleChange}
                            />
                            <span className="custom-checkbox"></span>
                        </label>
                    </div>

                    <div className="asset-detail">
                        <label>Detalhes da Garantia</label>
                        <select name="detalhes_garantia" value={formData.detalhes_garantia} onChange={handleChange}>
                            <option value="básica">Básica</option>
                            <option value="premium">Premium</option>
                            <option value="personalizada">Personalizada</option>
                        </select>
                    </div>

                    <div className="asset-detail">
                        <label>Validade da Garantia</label>
                        <input type="date" name="data_expiracao_garantia" value={formData.data_expiracao_garantia} onChange={handleChange} />
                    </div>

                    <div className="asset-detail">
                        <label>Observações</label>
                        <textarea name="observacoes" value={formData.observacoes} onChange={handleChange} />
                    </div>

                    <div className="modal-buttons">
                        <button type="submit" className="save-btn">Salvar</button>
                        <button type="button" className="cancel-btn" onClick={onClose}>Cancelar</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default EditAssetModal;
