import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './styles.min.css';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { imageUrls } from '../ativos/Data_Ativo';
import EditAsset from './EditAsset';
import DeleteAsset from './DeleteAsset';

const AssetList = () => {
    const [assets, setAssets] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // Novo estado para o termo de pesquisa

    useEffect(() => {
        document.title = 'Ativos | PinnSystem';
        
        const token = localStorage.getItem('token');

        axios.get('/api/assets/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            console.log('Dados recebidos da API:', response.data);
            setAssets(response.data);
        })
        .catch(error => console.error('Error fetching data: ', error));
    }, []);

    const groupedAssets = groupAssetsByType(assets);

    const handleEditClick = (asset) => {
        setSelectedAsset(asset);
        setIsEditing(true);
    };

    const handleDeleteClick = (asset) => {
        setSelectedAsset(asset);
        setIsDeleting(true);
    };

    const handleSave = (updatedAsset) => {
        setAssets(assets.map(asset => (asset.id === updatedAsset.id ? updatedAsset : asset)));
        setIsEditing(false);
    };

    const handleDelete = (assetId) => {
        setAssets(assets.filter(asset => asset.id !== assetId));
        setIsDeleting(false);
    };

    // Filtra os ativos com base no termo de pesquisa
    const filteredAssets = assets.filter(asset => 
        asset.nome_ativo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.especificacoes_tecnicas.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.tipo_ativo.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="asset-list">
            {/* Campo de pesquisa */}
            <input 
                type="text" 
                placeholder="Pesquisar ativos..." 
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)} 
                className="search-input"
            />

            {/* Renderização de ativos filtrados */}
            {Object.keys(groupAssetsByType(filteredAssets)).map((type) => (
                <div key={type} className="asset-group">
                    <h2>{type}</h2>
                    {groupAssetsByType(filteredAssets)[type].map(asset => (
                        <div key={asset.id} className="asset-item" onClick={() => handleEditClick(asset)}>
                            <img src={getImage(asset)} alt={asset.nome_ativo} className="asset-image" />
                            <div className="asset-details">
                                <h3>{getAssetName(asset)}</h3>
                                <p>{asset.especificacoes_tecnicas}</p>
                                <p>Status: <span className={`status-${getStatusClass(asset.status)}`}>{getStatus(asset.status)}</span></p>
                            </div>
                            <div className="asset-actions">
                                <button className="delete-btn" onClick={(e) => { e.stopPropagation(); handleDeleteClick(asset); }}>
                                    <DeleteForeverIcon className="delete-icon" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            
            {isEditing && <EditAsset asset={selectedAsset} onClose={() => setIsEditing(false)} onSave={handleSave} />}
            {isDeleting && <DeleteAsset asset={selectedAsset} onClose={() => setIsDeleting(false)} onDelete={handleDelete} />}
        </div>
    );
};

const getImage = (asset) => {
    return imageUrls[asset.modelo] || 'https://via.placeholder.com/100';
};

const getAssetName = (asset) => {
    return asset.modelo && asset.modelo !== 'Outro modelo' ? asset.modelo : asset.tipo_ativo;
};

const getStatus = (status) => {
    const statusMap = {
        'disponivel': 'Disponível',
        'em uso': 'Em uso',
        'em manutencao': 'Em manutenção'
    };
    return statusMap[status.toLowerCase()] || status;
};

const getStatusClass = (status) => {
    const statusClassMap = {
        'disponivel': 'disponivel',
        'em uso': 'emuso',
        'em manutencao': 'emmanutencao'
    };
    return statusClassMap[status.toLowerCase()] || 'default';
};

const groupAssetsByType = (assets) => {
    const groupedAssets = assets.reduce((acc, asset) => {
        const type = asset.tipo_ativo;
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(asset);
        return acc;
    }, {});
    console.log('Ativos agrupados por tipo:', groupedAssets);
    return groupedAssets;
};

export default AssetList;

