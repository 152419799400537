import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Button, IconButton, ListItemIcon, ListItemText, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleIcon from '@mui/icons-material/People';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './styles.min.css';
import i18n from '../../../i18n';
import USFlag from '../flags/us.png';
import BRFlag from '../flags/br.png';
import ESFlag from '../flags/es.png';

const Topbar = () => {
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
    const [notifMenuAnchorEl, setNotifMenuAnchorEl] = useState(null);
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [currentFlag, setCurrentFlag] = useState(BRFlag); // Defina a bandeira do Brasil como padrão
    const { t } = useTranslation();

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const token = localStorage.getItem('token');  // Certifique-se de que o token JWT esteja salvo no localStorage ou em outro lugar seguro
                const response = await axios.get('https://app.pinnsystem.com/api/alerts/', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response.data);
                setNotifications(response.data);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
    }, []);


    const handleUserMenuClick = (event) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleNotifMenuClick = (event) => {
        setNotifMenuAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClick = (event) => {
        setLanguageMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setUserMenuAnchorEl(null);
        setNotifMenuAnchorEl(null);
        setLanguageMenuAnchorEl(null);
    };

    const changeLanguage = (lng, flag) => {
        i18n.changeLanguage(lng);
        setCurrentFlag(flag);
        handleMenuClose();
    };

    return (
        <div className="topbar">
            {/* <Button variant="contained" color="primary" className="doc-button">
                {t('term')}
            </Button> */}
            <div className="user-menu">
                <IconButton color="inherit" onClick={handleNotifMenuClick}>
                    <Badge badgeContent={notifications.filter(notif => !notif.read).length} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <Menu
                    anchorEl={notifMenuAnchorEl}
                    open={Boolean(notifMenuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    {notifications.length === 0 ? (
                        <MenuItem>
                            <ListItemText primary={t('no_notifications')} />
                        </MenuItem>
                    ) : (
                        notifications.map((notif, index) => (
                            <MenuItem key={index}>
                                <ListItemText primary={notif.message} />
                            </MenuItem>
                        ))
                    )}
                </Menu>
                <IconButton color="inherit" onClick={handleUserMenuClick} className="user-icon">
                    <AccountCircleIcon />
                    <ArrowDropDownIcon />
                </IconButton>
                <Menu
                    anchorEl={userMenuAnchorEl}
                    open={Boolean(userMenuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleMenuClose}>
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={t('profile')} />
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={t('settings')} />
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <ListItemIcon>
                            <PaymentIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={t('payment')} />
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose}>
                        <ListItemIcon>
                            <PeopleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={t('referral')} />
                    </MenuItem>
                </Menu>
                <IconButton color="inherit" onClick={handleLanguageMenuClick} className="language-icon">
                    <img src={currentFlag} alt="Current Flag" className="flag-icon" />
                    <ArrowDropDownIcon />
                </IconButton>
                <Menu
                    anchorEl={languageMenuAnchorEl}
                    open={Boolean(languageMenuAnchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => changeLanguage('pt', BRFlag)}>
                        <img src={BRFlag} alt="Português" className="flag-icon" />
                        <ListItemText primary="Português" />
                    </MenuItem>
                    <MenuItem onClick={() => changeLanguage('en', USFlag)}>
                        <img src={USFlag} alt="English" className="flag-icon" />
                        <ListItemText primary="English" />
                    </MenuItem>
                    <MenuItem onClick={() => changeLanguage('es', ESFlag)}>
                        <img src={ESFlag} alt="Español" className="flag-icon" />
                        <ListItemText primary="Español" />
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default Topbar;