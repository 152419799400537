export const modelosPorFabricante = {
  Notebook: {
    Dell: [
      'Notebook Dell Inspiron I15-I120K-A20P',
      'Notebook Dell Inspiron I15-I120K-M45P',
      'Notebook Dell Inspiron I15-I120K-M30P',
      'Notebook Dell Inspiron I15-I1300-M80P',
      'Notebook Dell Inspiron I15-I120K-U10P',
      'Notebook Dell Inspiron I15-I120K-M25P',
      'Notebook Dell Inspiron I15-I1300-M50P',
      'Notebook Dell Inspiron I15-I120K-U25P',
      'Notebook Dell Inspiron I15-I120K-M15P',
      'Notebook Dell Inspiron I15-I1300-M60P',
      'Dell Notebook Inspiron 3520',
      'Notebook Dell Inspiron i13-i1300-M10S',
      'Notebook Dell Inspiron I15-I1300-M70P',
      'Notebook Dell Inspiron I15-I120K-M10P',
      'Notebook Dell Inspiron I15-I120K-M35P',
      'Notebook Dell Inspiron I15-I120K-U20P',
      'Notebook Dell Inspiron I15-I120K-U45P',
      'Notebook Dell Inspiron I15-A0507-U10P',
      'Notebook Dell Inspiron I15-I1300-U30P',
      'Dell Notebook Inspiron 15 3511',
      'Outro modelo'
    ],
    Lenovo: [
      'Notebook Lenovo IdeaPad 1i Celeron',
      'Notebook Lenovo Ideapad 1i',
      'Notebook Lenovo Ultrafino IdeaPad 3',
      'Notebook Lenovo IdeaPad 1 i3-1215U',
      'Notebook Gamer Lenovo LOQ Intel Core i5-12450H',
      'Lenovo ‎82X5S00100 Ideapad',
      'Notebook Gamer Lenovo LOQ Intel Core i5-12450H',
      'Notebook Lenovo IdeaPad 1i i5-1235U',
      'Lenovo 82MD0007BR IdeaPad 3i i5 - Notebook-1135G7',
      'Desktop Lenovo ThinkCentre neo 50q Gen4 Intel i5-13420H',
      'Notebook Lenovo IdeaPad 1i i7-1255U',
      'Lenovo Computador desktop ThinkCentre M70q 11DT0028US',
      'Notebook Lenovo IdeaPad 1i Intel Core i7-1255U',
      'Notebook Lenovo Yoga Slim 6 Intel Core i5-1240P',
      'Notebook Lenovo Ultrafino IdeaPad 1 i3-1215U',
      'Lenovo 82MG0009BR - Notebook ideapad Gaming 3i, i5-11300H',
      'Notebook Lenovo IdeaPad 1i Intel Core i5-1235U',
      'Notebook Gamer Lenovo Legion Pro 5 Intel Core i7-14700HX',
      'Notebook Lenovo IdeaPad 3i Celeron',
      'Notebook Lenovo Yoga Slim 6 Intel Core i7-1260P',
      'Computador Desktop Lenovo M70q i5-10400T',
      'Notebook Lenovo V14 AMD R5-5625U',
      'Notebook Lenovo IdeaPad 3i i3-1115G4',
      'Notebook Lenovo Ultrafino IdeaPad 1 R5-7520U',
      'Notebook Lenovo Ultrafino IdeaPad 1 R3-7320U',
      'Outro modelo'
      
    ],
    Asus: [
      'Notebook ASUS Vivobook 15, AMD Ryzen 5',
      'Notebook ASUS X515MA, Celeron Dual Core',
      'Notebook ASUS Vivobook Go 15, Intel Core i3 N305',
      'Notebook ASUS Vivobook 15 Intel Pentium Gold',
      'Notebook ASUS Vivobook Go 15, Intel Core i3 N305',
      'Notebook ASUS Vivobook 15, AMD Ryzen 7',
      'Notebook ASUS VivoBook Go 15, AMD RYZEN 5 7520U',
      'Notebook ASUS Vivobook 16 Core I7',
      'Notebook Gamer ASUS TUF F15 Intel Core i7',
      'Notebook ASUS E510MA, Celeron Dual Core',
      'Notebook Asus Vivobook 15 Core i5',
      'Notebook ASUS Vivobook 15, Intel Core i5',
      'Notebook ASUS E510MA, Intel Celeron Dual Core',
      'ASUS Zenbook Duo Laptop',
      'Notebook ASUS Vivobook 16X, Intel Core i5',
      'Asus Laptop Vivobook',
      'Notebook Asus Vivobook Go E1504ga Intel Core i3',
      'Computador Gamer Fácil Asus Intel Core i5',
      'ASUS Laptop Vivobook Go 15 2023',
      'Notebook ASUS VivoBook Go 15',
      'Notebook ASUS Vivobook 15, Intel Core i5',
      'Notebook Asus Vivobook X1502za Intel Core i5',
      'ASUS M515DA-BR1454W / AMD RYZEN 7',
      'Notebook ASUS Vivobook Go 15, Intel Core i3 N305',
      'Notebook Asus Vivobook 16 1605za-mb445w Intel Core i7',
      'ASUS Laptop Vivobook 14 2023',
      'ASUS Notebook Vivobook',
      'ASUS VIVOBOOK 15 X1502ZA-EJ611W',
      'Notebook Asus Vivobook 16 X1605za-mb313w Intel Core i7',
      'Notebook ASUS Vivobook Pro 15 Intel Core i9',
      'Notebook Asus Intel Core I5-12450h 20gb 1tb Ssd 15,6 Fhd',
      'Notebook Asus Vivobook X1502za',
      'ASUS 0 Vivobook Go 14 L410',
      'Notebook ASUS TUF Gaming F15 Intel Core i5',
      'Notebook ASUS Vivobook 16, Intel Core i7',
      'Notebook ASUS M515DA-BR1213W, AMD Ryzen 5',
      'Notebook Asus Vivobook X1502za Intel Core i5',
      'ASUS VivoBook 15',
      'Outro modelo'

    ],
    Samsung: [
      'Notebook Samsung Galaxy Book Go',
      'Samsung Galaxy Book4 Intel® Core™ i7-1355U',
      'Samsung Galaxy Book4 Intel® Core™ i5-1335U',
      'Galaxy Book2 Intel® Core™ i5-1235U',
      'Samsung Galaxy Book4 Intel® Core™ i5-1335U',
      'Samsung Galaxy Book3 360 Intel® Core™ i5-1335U',
      'Chromebook Samsung 11.6 Intel',
      'Galaxy Book2 Intel® Core™ i7-1255U',
      'Galaxy Book2 Intel® Core™ i3-1215U',
      'Galaxy Book2 Intel® Core™ i3-1215U',
      'SAMSUNG CHROMEBOOK INTEL® DUAL-CORE',
      'Samsung Galaxy Book2 360 Intel® Core™ i5-1235U',
      'Samsung Galaxy Book4 Intel® Core™ i3-1315U',
      'Samsung Book Core i5-1135G7',
      'Samsung Notebook Galaxy Book4',
      'Samsung Galaxy Book3 360 Intel® Core™ i7',
      'Samsung Book Celeron-6305',
      'Notebook Samsung Galaxy i5 8GB 256 SSD W11P NP754XFG-KF1BR',
      'Notebook Samsungbook Core I5-1235u',
      'Outro modelo'
      
    ],
    Apple: [
        'MacBook Air M1',
        'MacBook Pro 13" M1',
        'MacBook Air Retina',
        'MacBook Pro 13" Intel',
        'Mac Mini M1',
        'Mac Mini Intel',
        'iMac 21.5"',
        'MacBook 12" Retina',
        'MacBook Pro 15" Retina',
        'iMac 24" M1',
        'Outro modelo'
    ]
  },

Periféricos: {
    Dell: [
      "Dell WM126",
      "Dell MS116",
      "Dell MS5120W",
      "Dell Premier Wireless Mouse - WM527",
      "Dell Optical Mouse - MS3320",
      "Dell USB Optical Mouse - MS1021",
      "Dell Wireless Optical Mouse - WM524",
      "Dell Performance Wireless Mouse - WM527",
      "Dell Wireless Mouse - WM126",
      "Dell Mobile Wireless Mouse - MS3320",
      "Dell KB216",
      "Dell KB212-B",
      "Dell Pro Keyboard - KB522",
      "Dell Wireless Keyboard - KM636",
      "Dell KB113",
      "Dell KB413",
      "Dell KB813",
      "Dell KB813 Wireless",
      "Dell Multi-Device Keyboard - KM7321W",
      "Dell KM717 Wireless Keyboard and Mouse",
      "Dell Pro Stereo Headset",
      "Dell UC150 USB Headset",
      "Dell Premium Active Noise-Cancelling Headset",
      "Dell USB Headset - AE2",
      "Dell Pro Wireless Headset",
      "Dell Stereo Headset - AE2",
      "Dell Vostro Headset",
      "Dell Bluetooth Headset",
      "Dell Headset - EH01",
      "Dell USB Headset - UC350",
      "Dell C5519Q",
      "Dell 55 4K UHD Smart TV",
      "Dell P4317Q",
      "Dell S3221QS",
      "Dell C1422H",
      "Dell UltraSharp U4320Q",
      "Dell S2421H",
      "Dell S3221QS",
      "Dell UltraSharp U2720Q",
      "Dell P2720D"
    ],
    Lenovo: [
      "Lenovo 300 USB Mouse",
      "Lenovo ThinkPad Basic USB Mouse",
      "Lenovo 500 Wireless Mouse",
      "Lenovo ThinkPad Wireless Mouse",
      "Lenovo Laser Wireless Mouse",
      "Lenovo ThinkPad X1 Wireless Mouse",
      "Lenovo 400 USB Mouse",
      "Lenovo 600 Wireless Mouse",
      "Lenovo N70A Wireless Mouse",
      "Lenovo Wireless Mouse - N100",
      "Lenovo ThinkPad Compact USB Keyboard",
      "Lenovo Preferred Pro USB Keyboard",
      "Lenovo ThinkPad Bluetooth Keyboard",
      "Lenovo ThinkPad USB Keyboard with TrackPoint",
      "Lenovo Legion K500 RGB Gaming Keyboard",
      "Lenovo 300 USB Keyboard",
      "Lenovo 500 Wireless Keyboard",
      "Lenovo Professional Wireless Keyboard",
      "Lenovo ThinkPad X1 Carbon Keyboard",
      "Lenovo 100 Wired Keyboard",
      "Lenovo Stereo Headset",
      "Lenovo ThinkPad USB Headset",
      "Lenovo LP6 Wireless Headset",
      "Lenovo HD100 Bluetooth Headset",
      "Lenovo H301 Wired Headset",
      "Lenovo HT50 Wireless Headphones",
      "Lenovo H100 Wired Headset",
      "Lenovo ThinkPad X1 Headset",
      "Lenovo Wireless Headset - H600",
      "Lenovo HD300 Headset",
      "Lenovo Smart TV 4K",
      "Lenovo Smart TV X1",
      "Lenovo Smart TV 55-inch",
      "Lenovo Smart TV 65-inch",
      "Lenovo Smart TV 50-inch",
      "Lenovo Smart TV 43-inch",
      "Lenovo Smart TV 32-inch",
      "Lenovo Smart TV 28-inch",
      "Lenovo Smart TV 70-inch",
      "Lenovo Smart TV QLED"
    ],
    Logitech: [
      "Logitech MX Master 3",
      "Logitech M720 Triathlon",
      "Logitech MX Anywhere 3",
      "Logitech G502 HERO",
      "Logitech M185 Wireless Mouse",
      "Logitech M590 Multi-Device Silent",
      "Logitech MX Master 2S",
      "Logitech MX Vertical",
      "Logitech G Pro Wireless",
      "Logitech M330 Silent Plus",
      "Logitech K780",
      "Logitech MX Keys",
      "Logitech K380",
      "Logitech G915 TKL",
      "Logitech K400 Plus",
      "Logitech G513 RGB",
      "Logitech K270 Wireless Keyboard",
      "Logitech K860 Ergonomic Keyboard",
      "Logitech G610 Orion Brown",
      "Logitech K145",
      "Logitech H800 Bluetooth Headset",
      "Logitech G Pro X Gaming Headset",
      "Logitech H390 USB Headset",
      "Logitech H151 Wired Headset",
      "Logitech G433 Gaming Headset",
      "Logitech Zone Wired Headset",
      "Logitech H820e Wireless Headset",
      "Logitech H540 USB Headset",
      "Logitech G935 Wireless Gaming Headset",
      "Logitech G332 Stereo Gaming Headset"
    ],
    JBL: [
      "JBL Quantum 800",
      "JBL Live 650BTNC",
      "JBL Tune 750BTNC",
      "JBL Tune 500BT",
      "JBL Endurance Peak II",
      "JBL Quantum 400",
      "JBL Quantum 600",
      "JBL Quantum One",
      "JBL Live Pro+ TWS",
      "JBL Reflect Flow"
    ],
    // HP: [
    //   "HP Wireless Elite v2",
    //   "HP K2500 Wireless Keyboard",
    //   "HP 300 Wired Keyboard",
    //   "HP Premium Keyboard",
    //   "HP Pavilion Gaming Keyboard",
    //   "HP 125 Wireless Keyboard",
    //   "HP 100 Wired Keyboard",
    //   "HP Z Keyboard",
    //   "HP Spectre x360 Keyboard",
    //   "HP 600 Wired Keyboard",
    //   "HP X1000 Wireless Headset",
    //   "HP Pavilion Gaming Headset",
    //   "HP Wired Headset",
    //   "HP Bluetooth Headset",
    //   "HP USB Headset",
    //   "HP 200 Wireless Headset",
    //   "HP 400 Wired Headset",
    //   "HP 600 Wireless Headset",
    //   "HP Elite Wireless Headset",
    //   "HP Pavilion Wireless Headset",
    //   "HP Smart TV 4K",
    //   "HP Smart TV 65-inch",
    //   "HP Smart TV 55-inch",
    //   "HP Smart TV 50-inch",
    //   "HP Smart TV 43-inch",
    //   "HP Smart TV 32-inch",
    //   "HP Smart TV 70-inch",
    //   "HP Smart TV 28-inch",
    //   "HP Smart TV QLED",
    //   "HP Smart TV 8K"
    // ],
    // Microsoft: [
    //   "Microsoft Arc Mouse",
    //   "Microsoft Surface Mouse",
    //   "Microsoft Sculpt Ergonomic Mouse",
    //   "Microsoft Wireless Mobile Mouse 4000",
    //   "Microsoft Bluetooth Mouse",
    //   "Microsoft Modern Mobile Mouse",
    //   "Microsoft Surface Precision Mouse",
    //   "Microsoft Classic IntelliMouse",
    //   "Microsoft Designer Compact Mouse",
    //   "Microsoft Pro IntelliMouse",
    //   "Microsoft Surface Keyboard",
    //   "Microsoft Sculpt Ergonomic Keyboard",
    //   "Microsoft Wireless Keyboard 850",
    //   "Microsoft Modern Keyboard",
    //   "Microsoft Natural Ergonomic Keyboard 4000",
    //   "Microsoft Surface Pro Keyboard",
    //   "Microsoft Bluetooth Keyboard",
    //   "Microsoft Designer Compact Keyboard",
    //   "Microsoft All-in-One Media Keyboard",
    //   "Microsoft Classic IntelliMouse",
    //   "Microsoft Surface Headphones",
    //   "Microsoft Modern USB Headset",
    //   "Microsoft LifeChat LX-3000",
    //   "Microsoft Xbox Wireless Headset",
    //   "Microsoft Surface Earbuds",
    //   "Microsoft H5S-00002",
    //   "Microsoft H7S-00001",
    //   "Microsoft LifeChat LX-6000",
    //   "Microsoft Surface Headphones 2",
    //   "Microsoft Headset - 3.5mm"
    // ]
  
},


  Impressora: {
    HP: [
      'HP LaserJet Pro M404dn',
      'HP LaserJet Pro MFP M428fdw',
      'HP Color LaserJet Pro MFP M479fdw',
      'HP OfficeJet Pro 9015e',
      'HP LaserJet Enterprise M507dn',
      'HP LaserJet Enterprise MFP M528dn',
      'HP OfficeJet Pro 9025e',
      'HP LaserJet Pro MFP M227fdw',
      'HP LaserJet Pro MFP M281fdw',
      'HP Color LaserJet Enterprise MFP M681dh',
      'HP LaserJet Enterprise Flow MFP M830z',
      'HP Color LaserJet Pro M255dw',
      'HP LaserJet Pro M15w',
      'HP LaserJet Pro M203dw',
      'HP OfficeJet 200 Mobile Printer',
      'HP PageWide Pro 477dw',
      'HP LaserJet Pro 200 M251nw',
      'HP LaserJet Pro MFP M130fn',
      'HP Color LaserJet Pro M182nw',
      'Outro modelo'
    ],
    Canon: [
      'Canon imageCLASS MF269dw',
      'Canon imageCLASS MF445dw',
      'Canon PIXMA TR8620a',
      'Canon imageCLASS MF644Cdw',
      'Canon imageCLASS MF743Cdw',
      'Canon imageRUNNER 1643iF',
      'Canon MAXIFY MB2720',
      'Canon PIXMA G7020',
      'Canon imageCLASS LBP6230dw',
      'Canon imageCLASS D570',
      'Canon imageCLASS MF424dw',
      'Canon imageCLASS MF3010',
      'Canon imageRUNNER 2530',
      'Canon PIXMA MG3620',
      'Canon Color imageCLASS MF810Cdn',
      'Canon imageRUNNER 1435iF',
      'Canon imageCLASS MF236n',
      'Canon imageCLASS MF269dw VP',
      'Canon PIXMA TS6320',
      'Outro modelo'
    ]
  },
  
  Monitor: {
    Samsung: [
      'Monitor Samsung 24" Full HD',
      'Monitor Samsung 27" 4K UHD',
      'Monitor Samsung Odyssey G7 27" Curved',
      'Monitor Samsung 32" 4K UHD',
      'Monitor Samsung 49" Curved Ultra-Wide',
      'Monitor Samsung S80A 32" 4K UHD',
      'Monitor Samsung 27" Odyssey G5 QHD',
      'Monitor Samsung 34" Curved Ultra-Wide',
      'Outro Modelo Monitor',
    ],
    LG: [
      'Monitor LG UltraWide 29"',
      'Monitor LG 27" 4K UHD',
      'Monitor LG 34" Curved UltraWide',
      'Monitor LG 27" QHD',
      'Monitor LG 32" 4K UHD',
      'Monitor LG 34" UltraWide 21:9',
      'Monitor LG 29" UltraWide Full HD',
      'Monitor LG 27" UltraGear Gaming Monitor',
      'Outro Modelo Monitor',
    ],
    Dell: [
      'Monitor Dell UltraSharp U2720Q 27" 4K',
      'Monitor Dell P2719H 27" Full HD',
      'Monitor Dell U3818DW 38" Curved UltraWide',
      'Monitor Dell S2721D 27" QHD',
      'Monitor Dell S3221QS 32" 4K Curved',
      'Monitor Dell UltraSharp U2419HC 24" Full HD',
      'Monitor Dell P2419H 24" Full HD',
      'Monitor Dell Alienware AW2521H 25" 360Hz',
      'Outro Modelo Monitor',
    ],
    ASUS: [
      'Monitor ASUS ROG Swift PG259QN 25" 360Hz',
      'Monitor ASUS TUF Gaming VG27AQ 27" QHD',
      'Monitor ASUS ProArt PA32UCX 32" 4K',
      'Monitor ASUS ROG Strix XG438Q 43" 4K UHD',
      'Monitor ASUS MG278Q 27" WQHD',
      'Monitor ASUS VG245H 24" Full HD',
      'Monitor ASUS VZ27EHE 27" Full HD',
      'Monitor ASUS ProArt PA34VC 34" Curved UltraWide',
      'Outro Modelo Monitor',
    ],
    Acer: [
      'Monitor Acer Predator X27 27" 4K UHD',
      'Monitor Acer R240HY 24" Full HD',
      'Monitor Acer Nitro XV273K 27" 4K UHD',
      'Monitor Acer CB342CUR 34" Curved UltraWide',
      'Monitor Acer KG271 27" Full HD',
      'Monitor Acer Predator XB273K 27" 4K UHD',
      'Monitor Acer ED273 27" Curved Full HD',
      'Monitor Acer B247Y 24" Full HD',
      'Outro Modelo Monitor',
    ],
  },
  Smartphone: {
    Apple: [
      'iPhone 5',
      'iPhone 5c',
      'iPhone 6',
      'iPhone 6 Plus',
      'iPhone 7',
      'iPhone 7 Plus',
      'iPhone 8',
      'iPhone 8 Plus',
      'iPhone X',
      'iPhone Xr',
      'iPhone Xs',
      'iPhone Xs Max',
      'iPhone 11',
      'iPhone 11 Pro',
      'iPhone 11 Pro Max',
      'iPhone 12',
      'iPhone 12 Mini',
      'iPhone 12 Pro',
      'iPhone 12 Pro Max',
      'iPhone 13',
      'iPhone 13 Mini',
      'iPhone 13 Pro',
      'iPhone 13 Pro Max',
      'iPhone 14',
      'iPhone 14 Plus',
      'iPhone 14 Pro',
      'iPhone 14 Pro Max',
      'iPhone 15',
      'iPhone 15 Plus',
      'iPhone 15 Pro',
      'iPhone 15 Pro Max',
      'outro modelo Smartphone',
    ],
    Samsung: [
      'Samsung Galaxy S23 Ultra',
      'Samsung Galaxy S23 Plus',
      'Samsung Galaxy S23',
      'Samsung Galaxy Z Fold 5',
      'Samsung Galaxy Z Flip 5',
      'Samsung Galaxy A54 5G',
      'Samsung Galaxy A34 5G',
      'Samsung Galaxy S22 Ultra',
      'Samsung Galaxy S22 Plus',
      'Samsung Galaxy S22',
      'Samsung Galaxy A14',
      'Samsung Galaxy M33 5G',
      'Samsung Galaxy A13',
      'Samsung Galaxy A04s',
      'Samsung Galaxy M53 5G',
      'Samsung Galaxy A73 5G',
      'Samsung Galaxy A23 5G',
      'Samsung Galaxy Z Fold 4',
      'Samsung Galaxy Z Flip 4',
      'Samsung Galaxy A53 5G',
      'outro modelo Smartphone'
    ],
    LG: [
      'LG Velvet',
      'LG Wing 5G',
      'LG G8 ThinQ',
      'LG V60 ThinQ 5G',
      'LG V50 ThinQ 5G',
      'LG G7 ThinQ',
      'LG K92 5G',
      'LG Stylo 6',
      'LG K71',
      'LG V40 ThinQ',
      'LG K51',
      'LG G6',
      'LG Q60',
      'LG K31',
      'LG K40',
      'LG V35 ThinQ',
      'LG Q70',
      'LG V30',
      'LG G5',
      'LG K22',
      'outro modelo Smartphone'
    ],
    Redmi: [
      'Redmi Note 12 Pro',
      'Redmi Note 12',
      'Redmi Note 11 Pro',
      'Redmi Note 11',
      'Redmi Note 10 Pro',
      'Redmi Note 10',
      'Redmi Note 9 Pro',
      'Redmi Note 9',
      'Redmi K40 Pro',
      'Redmi K40',
      'Redmi K30 Pro',
      'Redmi K30',
      'Redmi 10',
      'Redmi 10 Prime',
      'Redmi 9T',
      'Redmi 9',
      'Redmi 8',
      'Redmi 7',
      'Redmi 6',
      'outro modelo Smartphone'
    ],  
  },
  Tablet: {
    Apple: [
      'iPad Mini',
      'iPad 9ª Geração',
      'iPad Air 4ª Geração',
      'iPad Pro 11" (2021)',
      'iPad Pro 12.9" (2021)',
      'iPad Air 5ª Geração',
      'iPad Pro 11" (2022)',
      'iPad Pro 12.9" (2022)',
      'Outro Modelo Tablet',
    ],
    Samsung: [
      'Samsung Galaxy Tab S8',
      'Samsung Galaxy Tab S8+',
      'Samsung Galaxy Tab S8 Ultra',
      'Samsung Galaxy Tab S7',
      'Samsung Galaxy Tab S7+',
      'Samsung Galaxy Tab A7',
      'Samsung Galaxy Tab Active3',
      'Samsung Galaxy Tab A8',
      'Outro Modelo Tablet',
    ],
    Microsoft: [
      'Microsoft Surface Pro 9',
      'Microsoft Surface Pro 8',
      'Microsoft Surface Pro 7',
      'Microsoft Surface Go 3',
      'Microsoft Surface Go 2',
      'Microsoft Surface Book 3',
      'Microsoft Surface Pro X',
      'Outro Modelo Tablet',
    ],
    Lenovo: [
      'Lenovo Tab P12 Pro',
      'Lenovo Tab P11',
      'Lenovo Tab M10 Plus',
      'Lenovo Tab P11 Plus',
      'Lenovo Yoga Tab 13',
      'Lenovo Tab M8',
      'Lenovo Tab 11',
      'Outro Modelo Tablet',
    ],
  }
};


export const imageUrls = {
  'Notebook Dell Inspiron I15-I120K-A20P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-M45P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-M30P': require('../../assets/images/Dell/Notebook Dell Inspiron I15-I120K-M30P.png'),
  'Dell Notebook Inspiron 15 3511': require('../../assets/images/Dell/Dell Notebook Inspiron 15 3511.png'),
  'Notebook Dell Inspiron I15-I1300-M80P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-U10P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-M25P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I1300-M50P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-U25P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-M15P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I1300-M60P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Dell Notebook Inspiron 3520': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron i13-i1300-M10S': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I1300-M70P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-M10P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-M35P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-U20P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I120K-U45P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-A0507-U10P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),
  'Notebook Dell Inspiron I15-I1300-U30P': require('../../assets/images/Notebook_Dell_Inspiron_I15-I120K-A20P.png'),


  // lenovo
  'Notebook Lenovo IdeaPad 1i Celeron': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo Ideapad 1i': require('../../assets/images/lenovo/image1.png'),
  'Notebook Lenovo Ultrafino IdeaPad 3': require('../../assets/images/lenovo/image2.png'),
  'Notebook Lenovo IdeaPad 1 i3-1215U':  require('../../assets/images/lenovo/image.png'),
  'Notebook Gamer Lenovo LOQ Intel Core i5-12450H':  require('../../assets/images/lenovo/image.png'),
  'Lenovo ‎82X5S00100 Ideapad': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo IdeaPad 1i i5-1235U': require('../../assets/images/lenovo/image.png'),
  'Lenovo 82MD0007BR IdeaPad 3i i5 - Notebook-1135G7': require('../../assets/images/lenovo/image.png'),
  'Desktop Lenovo ThinkCentre neo 50q Gen4 Intel i5-13420H': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo IdeaPad 1i i7-1255U': require('../../assets/images/lenovo/image.png'),
  'Lenovo Computador desktop ThinkCentre M70q 11DT0028US': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo IdeaPad 1i Intel Core i7-1255U': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo Yoga Slim 6 Intel Core i5-1240P': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo Ultrafino IdeaPad 1 i3-1215U': require('../../assets/images/lenovo/image.png'),
  'Lenovo 82MG0009BR - Notebook ideapad Gaming 3i, i5-11300H': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo IdeaPad 1i Intel Core i5-1235U': require('../../assets/images/lenovo/image.png'),
  'Notebook Gamer Lenovo Legion Pro 5 Intel Core i7-14700HX': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo IdeaPad 3i Celeron': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo Yoga Slim 6 Intel Core i7-1260P': require('../../assets/images/lenovo/image.png'),
  'Computador Desktop Lenovo M70q i5-10400T': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo V14 AMD R5-5625U': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo IdeaPad 3i i3-1115G4': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo Ultrafino IdeaPad 1 R5-7520U': require('../../assets/images/lenovo/image.png'),
  'Notebook Lenovo Ultrafino IdeaPad 1 R3-7320U': require('../../assets/images/lenovo/image.png'),

  // asus 
  'Notebook ASUS Vivobook 15, AMD Ryzen 5': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS X515MA, Celeron Dual Core': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook Go 15, Intel Core i3 N305': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook 15 Intel Pentium Gold': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook 15, AMD Ryzen 7': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS VivoBook Go 15, AMD RYZEN 5 7520U': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook 16 Core I7': require('../../assets/images/asus/asus.png'),
  'Notebook Gamer ASUS TUF F15 Intel Core i7': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS E510MA, Celeron Dual Core': require('../../assets/images/asus/asus.png'),
  'Notebook Asus Vivobook 15 Core i5': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook 15, Intel Core i5': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS E510MA, Intel Celeron Dual Core': require('../../assets/images/asus/asus.png'),
  'ASUS Zenbook Duo Laptop': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook 16X, Intel Core i5': require('../../assets/images/asus/asus.png'),
  'Asus Laptop Vivobook': require('../../assets/images/asus/asus.png'),
  'Notebook Asus Vivobook Go E1504ga Intel Core i3': require('../../assets/images/asus/asus.png'),
  'Computador Gamer Fácil Asus Intel Core i5': require('../../assets/images/asus/asus.png'),
  'ASUS Laptop Vivobook Go 15 2023': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS VivoBook Go 15': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook 15, Intel Core i5': require('../../assets/images/asus/asus.png'),
  'Notebook Asus Vivobook X1502za Intel Core i5': require('../../assets/images/asus/asus.png'),
  'ASUS M515DA-BR1454W / AMD RYZEN 7': require('../../assets/images/asus/asus.png'),
  'Notebook Asus Vivobook 16 1605za-mb445w Intel Core i7': require('../../assets/images/asus/asus.png'),
  'ASUS Laptop Vivobook 14 2023':require('../../assets/images/asus/asus.png'),
  'ASUS Notebook Vivobook':require('../../assets/images/asus/asus.png'),
  'ASUS VIVOBOOK 15 X1502ZA-EJ611W': require('../../assets/images/asus/asus.png'),
  'Notebook Asus Vivobook 16 X1605za-mb313w Intel Core i7': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook Pro 15 Intel Core i9': require('../../assets/images/asus/asus.png'),
  'Notebook Asus Intel Core I5-12450h 20gb 1tb Ssd 15,6 Fhd': require('../../assets/images/asus/asus.png'),
  'Notebook Asus Vivobook X1502za': require('../../assets/images/asus/asus.png'),
  'ASUS 0 Vivobook Go 14 L410': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS TUF Gaming F15 Intel Core i5': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS Vivobook 16, Intel Core i7': require('../../assets/images/asus/asus.png'),
  'Notebook ASUS M515DA-BR1213W, AMD Ryzen 5': require('../../assets/images/asus/asus.png'),
  'Notebook Asus Vivobook X1502za Intel Core i5': require('../../assets/images/asus/asus.png'),
  'ASUS VivoBook 15': require('../../assets/images/asus/asus.png'),

  // samsung 
  'Notebook Samsung Galaxy Book Go': require('../../assets/images/samsung/samsung.png'),
  'Samsung Galaxy Book4 Intel® Core™ i7-1355U': require('../../assets/images/samsung/samsung.png'),
  'Samsung Galaxy Book4 Intel® Core™ i5-1335U': require('../../assets/images/samsung/samsung.png'),
  'Galaxy Book2 Intel® Core™ i5-1235U': require('../../assets/images/samsung/samsung.png'),
  'Samsung Galaxy Book3 360 Intel® Core™ i5-1335U': require('../../assets/images/samsung/samsung.png'),
  'Chromebook Samsung 11.6 Intel': require('../../assets/images/samsung/samsung.png'),
  'Galaxy Book2 Intel® Core™ i7-1255U': require('../../assets/images/samsung/samsung.png'),
  'Galaxy Book2 Intel® Core™ i3-1215U': require('../../assets/images/samsung/samsung.png'),
  'SAMSUNG CHROMEBOOK INTEL® DUAL-CORE': require('../../assets/images/samsung/samsung.png'),
  'Samsung Galaxy Book2 360 Intel® Core™ i5-1235U': require('../../assets/images/samsung/samsung.png'),
  'Samsung Galaxy Book4 Intel® Core™ i3-1315U':require('../../assets/images/samsung/samsung.png'),
  'Samsung Book Core i5-1135G7': require('../../assets/images/samsung/samsung.png'),
  'Samsung Notebook Galaxy Book4': require('../../assets/images/samsung/samsung.png'),
  'Samsung Galaxy Book3 360 Intel® Core™ i7': require('../../assets/images/samsung/samsung.png'),
  'Samsung Book Celeron-6305': require('../../assets/images/samsung/samsung.png'),
  'Notebook Samsung Galaxy i5 8GB 256 SSD W11P NP754XFG-KF1BR': require('../../assets/images/samsung/samsung.png'),
  'Notebook Samsungbook Core I5-1235u': require('../../assets/images/samsung/samsung.png'),


  // apple
  'MacBook Air M1': require('../../assets/images/apple/apple.png'),
  'MacBook Pro 13" M1': require('../../assets/images/apple/apple.png'),
  'MacBook Air Retina': require('../../assets/images/apple/apple.png'),
  'MacBook Pro 13" Intel': require('../../assets/images/apple/apple.png'),
  'Mac Mini M1': require('../../assets/images/apple/apple.png'),
  'Mac Mini Intel': require('../../assets/images/apple/apple.png'),
  'iMac 21.5"': require('../../assets/images/apple/apple.png'),
  'MacBook 12" Retina': require('../../assets/images/apple/apple.png'),
  'MacBook Pro 15" Retina': require('../../assets/images/apple/apple.png'),
  'iMac 24" M1': require('../../assets/images/apple/apple.png'),

  'Outro modelo': require('../../assets/images/outro modelo/outromodelo.png'),

  // Iphones
  'iPhone 5': require('../../assets/images/Iphones/iphone5.png'),
  'iPhone 5c': require('../../assets/images/Iphones/iphone5c.png'),
  'iPhone 6': require('../../assets/images/Iphones/6 plus.png'),
  'iPhone 6 Plus': require('../../assets/images/Iphones/6 plus.png'),
  'iPhone 7': require('../../assets/images/Iphones/7.png'),
  'iPhone 7 Plus': require('../../assets/images/Iphones/7 plus.png'),
  'iPhone 8': require('../../assets/images/Iphones/8.png'),
  'iPhone 8 Plus': require('../../assets/images/Iphones/8 plus.png'),
  'iPhone X': require('../../assets/images/Iphones/x.png'),
  'iPhone Xr': require('../../assets/images/Iphones/xr.png'),
  'iPhone Xs': require('../../assets/images/Iphones/xs.png'),
  'iPhone Xs Max': require('../../assets/images/Iphones/xs.png'),
  'iPhone 11': require('../../assets/images/Iphones/11.png'),
  'iPhone 11 Pro': require('../../assets/images/Iphones/11 pro.png'),
  'iPhone 11 Pro Max': require('../../assets/images/Iphones/11 pro max.png'),
  'iPhone 12': require('../../assets/images/Iphones/12.png'),
  'iPhone 12 Mini': require('../../assets/images/Iphones/12 mini.png'),
  'iPhone 12 Pro': require('../../assets/images/Iphones/12 pro.png'),
  'iPhone 12 Pro Max': require('../../assets/images/Iphones/12 pro max.png'),
  'iPhone 13': require('../../assets/images/Iphones/13.png'),
  'iPhone 13 Mini': require('../../assets/images/Iphones/13 pro.png'),
  'iPhone 13 Pro': require('../../assets/images/Iphones/13 pro.png'),
  'iPhone 13 Pro Max': require('../../assets/images/Iphones/13 pro max.png'),
  'iPhone 14': require('../../assets/images/Iphones/14.png'),
  'iPhone 14 Plus': require('../../assets/images/Iphones/14 plus.png'),
  'iPhone 14 Pro': require('../../assets/images/Iphones/14 pro.png'),
  'iPhone 14 Pro Max': require('../../assets/images/Iphones/14 pro max.png'),
  'iPhone 15': require('../../assets/images/Iphones/15.png'),
  'iPhone 15 Plus': require('../../assets/images/Iphones/15 plus.png'),
  'iPhone 15 Pro': require('../../assets/images/Iphones/15 pro.png'),
  'iPhone 15 Pro Max': require('../../assets/images/Iphones/15 pro.png'),
  'outro modelo Smartphone': require('../../assets/images/Iphones/generic.png'),

  // smartphone samsung 
  'Samsung Galaxy S23 Ultra': require('../../assets/images/samsung/s3 ultra.png'),
  'Samsung Galaxy S23 Plus': require('../../assets/images/samsung/s23 plus.png'),
  'Samsung Galaxy S23': require('../../assets/images/samsung/s22.png'),
  'Samsung Galaxy Z Fold 5': require('../../assets/images/samsung/z fold.png'),
  'Samsung Galaxy Z Flip 5': require('../../assets/images/samsung/z flip.png'),
  'Samsung Galaxy A54 5G': require('../../assets/images/samsung/a54.png'),
  'Samsung Galaxy A34 5G': require('../../assets/images/samsung/a34.png'),
  'Samsung Galaxy S22 Ultra': require('../../assets/images/samsung/s22 plus.png'),
  'Samsung Galaxy S22 Plus': require('../../assets/images/samsung/s22 plus.png'),
  'Samsung Galaxy S22': require('../../assets/images/samsung/s22.png'),
  'Samsung Galaxy A14': require('../../assets/images/samsung/a14.png'),
  'Samsung Galaxy M33 5G': require('../../assets/images/samsung/m33.png'),
  'Samsung Galaxy A13': require('../../assets/images/samsung/a13.png'),
  'Samsung Galaxy A04s': require('../../assets/images/samsung/a04s.png'),
  'Samsung Galaxy M53 5G': require('../../assets/images/samsung/m53.png'),
  'Samsung Galaxy A73 5G': require('../../assets/images/samsung/a73.png'),
  'Samsung Galaxy A23 5G': require('../../assets/images/samsung/a23.png'),
  'Samsung Galaxy Z Fold 4': require('../../assets/images/samsung/z fold 4.png'),
  'Samsung Galaxy Z Flip 4': require('../../assets/images/samsung/z flip.png'),
  'Samsung Galaxy A53 5G': require('../../assets/images/samsung/a53.png'),
  'outro modelo Smartphone': require('../../assets/images/samsung/generic.png'),

  // lg smartphones
  'LG Velvet': require('../../assets/images/LG/velvet.png'),
  'LG Wing 5G': require('../../assets/images/LG/wing.png'),
  'LG G8 ThinQ': require('../../assets/images/LG/thinq.png'),
  'LG V60 ThinQ 5G': require('../../assets/images/LG/V60.png'),
  'LG V50 ThinQ 5G': require('../../assets/images/LG/v50.png'),
  'LG G7 ThinQ': require('../../assets/images/LG/g7.png'),
  'LG K92 5G': require('../../assets/images/LG/k92.png'),
  'LG Stylo 6': require('../../assets/images/LG/stylo 6.png'),
  'LG K71': require('../../assets/images/LG/k71.png'),
  'LG V40 ThinQ': require('../../assets/images/LG/v40.png'),
  'LG K51': require('../../assets/images/LG/k51.png'),
  'LG G6': require('../../assets/images/LG/g6.png'),
  'LG Q60': require('../../assets/images/LG/q60.png'),
  'LG K31': require('../../assets/images/LG/k31.png'),
  'LG K40': require('../../assets/images/LG/k40.png'),
  'LG V35 ThinQ': require('../../assets/images/LG/v35.png'),
  'LG Q70': require('../../assets/images/LG/q70.png'),
  'LG V30': require('../../assets/images/LG/v30.png'),
  'LG G5': require('../../assets/images/LG/g5.png'),
  'LG K22': require('../../assets/images/LG/k22.png'),
  'outro modelo Smartphone': require('../../assets/images/LG/generic.png'),

  // smartphone RedMi
  'Redmi Note 12 Pro': require('../../assets/images/RedMi/note 12 pro.png'),
  'Redmi Note 12': require('../../assets/images/RedMi/note 12.png'),
  'Redmi Note 11 Pro': require('../../assets/images/RedMi/note 11 pro.png'),
  'Redmi Note 11': require('../../assets/images/RedMi/11.png'),
  'Redmi Note 10 Pro': require('../../assets/images/RedMi/10 pro].png'),
  'Redmi Note 10': require('../../assets/images/RedMi/10.png'),
  'Redmi Note 9 Pro': require('../../assets/images/RedMi/note 9 pro.png'),
  'Redmi Note 9': require('../../assets/images/RedMi/note 9.png'),
  'Redmi K40 Pro': require('../../assets/images/RedMi/k40 pro.png'),
  'Redmi K40': require('../../assets/images/RedMi/k40.png'),
  'Redmi K30 Pro': require('../../assets/images/RedMi/k30 pro.png'),
  'Redmi K30': require('../../assets/images/RedMi/k30.png'),
  'Redmi 10': require('../../assets/images/RedMi/10.png'),
  'Redmi 10 Prime': require('../../assets/images/RedMi/10 prime.png'),
  'Redmi 9T': require('../../assets/images/RedMi/9t.png'),
  'Redmi 9': require('../../assets/images/RedMi/9.png'),
  'Redmi 8': require('../../assets/images/RedMi/8.png'),
  'Redmi 7': require('../../assets/images/RedMi/7.png'),
  'Redmi 6': require('../../assets/images/RedMi/6.png'),
  'outro modelo Smartphone': require('../../assets/images/RedMi/generic.png'),

  // tablets
  'Ipad mini': require('../../assets/images/apple/image copy.png'),


  // monitores
  'Monitor Samsung 24" Full HD': require('../../assets/images/samsung/Monitor Samsung 24" Full HD.png'),
  'Monitor Samsung 27" 4K UHD': require('../../assets/images/samsung/t2.png'),
  'Monitor Samsung Odyssey G7 27" Curved': require('../../assets/images/samsung/t3.png'),
  'Monitor Samsung 32" 4K UHD': require('../../assets/images/samsung/t4.png'),
  'Monitor Samsung 49" Curved Ultra-Wide': require('../../assets/images/samsung/t5.png'),
  'Monitor Samsung S80A 32" 4K UHD': require('../../assets/images/samsung/t6.png'),
  'Monitor Samsung 27" Odyssey G5 QHD': require('../../assets/images/samsung/t7.png'),
  'Monitor Samsung 34" Curved Ultra-Wide': require('../../assets/images/samsung/t8.png'),
  'outro modelo Monitor': require('../../assets/images/acer/generic.png'),
  
  'Monitor LG UltraWide 29"': require('../../assets/images/LG/t1.png'),
  'Monitor LG 27" 4K UHD': require('../../assets/images/LG/t2,.png'),
  'Monitor LG 34" Curved UltraWide': require('../../assets/images/LG/t3.png'),
  'Monitor LG 27" QHD': require('../../assets/images/LG/t4.png'),
  'Monitor LG 32" 4K UHD': require('../../assets/images/LG/t5.png'),
  'Monitor LG 34" UltraWide 21:9': require('../../assets/images/LG/t4.png'),
  'Monitor LG 29" UltraWide Full HD': require('../../assets/images/LG/t4.png'),
  'Monitor LG 27" UltraGear Gaming Monitor': require('../../assets/images/LG/t4.png'),
  'outro modelo Monitor': require('../../assets/images/acer/generic.png'),

  'Monitor Dell UltraSharp U2720Q 27" 4K': require('../../assets/images/Dell/t1.png'),
  'Monitor Dell P2719H 27" Full HD': require('../../assets/images/Dell/t2.png'),
  'Monitor Dell U3818DW 38" Curved UltraWide': require('../../assets/images/Dell/t3.png'),
  'Monitor Dell S2721D 27" QHD': require('../../assets/images/Dell/t2.png'),
  'Monitor Dell S3221QS 32" 4K Curved': require('../../assets/images/Dell/t2.png'),
  'Monitor Dell UltraSharp U2419HC 24" Full HD': require('../../assets/images/Dell/t2.png'),
  'Monitor Dell P2419H 24" Full HD': require('../../assets/images/Dell/t2.png'),
  'Monitor Dell Alienware AW2521H 25" 360Hz': require('../../assets/images/Dell/t2.png'),
  'outro modelo Monitor': require('../../assets/images/acer/generic.png'),

  'Monitor ASUS ROG Swift PG259QN 25" 360Hz': require('../../assets/images/asus/t1.png'),
  'Monitor ASUS TUF Gaming VG27AQ 27" QHD': require('../../assets/images/asus/t2.png'),
  'Monitor ASUS ProArt PA32UCX 32" 4K': require('../../assets/images/asus/t3.png'),
  'Monitor ASUS ROG Strix XG438Q 43" 4K UHD': require('../../assets/images/asus/t2.png'),
  'Monitor ASUS MG278Q 27" WQHD': require('../../assets/images/asus/t2.png'),
  'Monitor ASUS VG245H 24" Full HD': require('../../assets/images/asus/t2.png'),
  'Monitor ASUS VZ27EHE 27" Full HD': require('../../assets/images/asus/t2.png'),
  'Monitor ASUS ProArt PA34VC 34" Curved UltraWide': require('../../assets/images/asus/t2.png'),
  'outro modelo Monitor': require('../../assets/images/acer/generic.png'),

  'Monitor Acer Predator X27 27" 4K UHD': require('../../assets/images/acer/t1.png'),
  'Monitor Acer R240HY 24" Full HD': require('../../assets/images/acer/t2.png'),
  'Monitor Acer Nitro XV273K 27" 4K UHD': require('../../assets/images/acer/t3.png'),
  'Monitor Acer CB342CUR 34" Curved UltraWide': require('../../assets/images/acer/t2.png'),
  'Monitor Acer KG271 27" Full HD': require('../../assets/images/acer/t2.png'),
  'Monitor Acer Predator XB273K 27" 4K UHD': require('../../assets/images/acer/t2.png'),
  'Monitor Acer ED273 27" Curved Full HD': require('../../assets/images/acer/t2.png'),
  'Monitor Acer B247Y 24" Full HD': require('../../assets/images/acer/t2.png'),
  'outro modelo Monitor': require('../../assets/images/acer/generic.png'),

  'iPad Mini': require('../../assets/images/apple/t1.png'),
  'iPad 9ª Geração': require('../../assets/images/apple/t2.png'),
  'iPad Air 4ª Geração': require('../../assets/images/apple/t3.png'),
  'iPad Pro 11" (2021)': require('../../assets/images/apple/t4.png'),
  'iPad Pro 12.9" (2021)': require('../../assets/images/apple/t4.png'),
  'iPad Air 5ª Geração': require('../../assets/images/apple/t4.png'),
  'iPad Pro 11" (2022)': require('../../assets/images/apple/t4.png'),
  'iPad Pro 12.9" (2022)': require('../../assets/images/apple/t4.png'),
  'Samsung Galaxy Tab S8': require('../../assets/images/samsung/ts1.png'),
  'Samsung Galaxy Tab S8+': require('../../assets/images/samsung/ts2.png'),
  'Samsung Galaxy Tab S8 Ultra': require('../../assets/images/samsung/ts3.png'),
  'Samsung Galaxy Tab S7': require('../../assets/images/samsung/ts2.png'),
  'Samsung Galaxy Tab S7+': require('../../assets/images/samsung/ts2.png'),
  'Samsung Galaxy Tab A7': require('../../assets/images/samsung/ts2.png'),
  'Samsung Galaxy Tab Active3': require('../../assets/images/samsung/ts2.png'),
  'Samsung Galaxy Tab A8': require('../../assets/images/samsung/ts2.png'),
  'Microsoft Surface Pro 9': require('../../assets/images/microsoft/t1.png'),
  'Microsoft Surface Pro 8': require('../../assets/images/microsoft/t1.png'),
  'Microsoft Surface Pro 7': require('../../assets/images/microsoft/t1.png'),
  'Microsoft Surface Go 3': require('../../assets/images/microsoft/t1.png'),
  'Microsoft Surface Go 2': require('../../assets/images/microsoft/t1.png'),
  'Microsoft Surface Book 3': require('../../assets/images/microsoft/t1.png'),
  'Microsoft Surface Pro X': require('../../assets/images/microsoft/t1.png'),
  'Lenovo Tab P12 Pro': require('../../assets/images/lenovo/t1.png'),
  'Lenovo Tab P11': require('../../assets/images/lenovo/t1.png'),
  'Lenovo Tab M10 Plus': require('../../assets/images/lenovo/t1.png'),
  'Lenovo Tab P11 Plus': require('../../assets/images/lenovo/t1.png'),
  'Lenovo Yoga Tab 13': require('../../assets/images/lenovo/t1.png'),
  'Lenovo Tab M8': require('../../assets/images/lenovo/t1.png'),
  'Lenovo Tab 11': require('../../assets/images/lenovo/t1.png'),
  'Outro modelo Tablet': require('../../assets/images/generic.png'),



  // perifericos
  'Dell WM126': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell MS116': require('../../assets/images/Dell_perifericos/Dell_MS116.png'),
  'Dell MS5120W': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell Premier Wireless Mouse - WM527': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell Optical Mouse - MS3320': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell USB Optical Mouse - MS1021': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell Wireless Optical Mouse - WM524': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell Performance Wireless Mouse - WM527': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell Wireless Mouse - WM126': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell Mobile Wireless Mouse - MS3320': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell KB216': require('../../assets/images/Dell_perifericos/Dell_KB216.png'),
  'Dell KB212-B': require('../../assets/images/Dell_perifericos/Dell_WM126.png'),
  'Dell Pro Keyboard - KB522': require('../../assets/images/Dell_perifericos/Dell_Wireless_Keyboard_KM636.png'),
  'Dell Wireless Keyboard - KM636': require('../../assets/images/Dell_perifericos/Dell_Wireless_Keyboard_KM636.png'),
  'Dell KB113': require('../../assets/images/Dell_perifericos/Dell_Wireless_Keyboard_KM636.png'),
  'Dell KB413': require('../../assets/images/Dell_perifericos/Dell_Wireless_Keyboard_KM636.png'),
  'Dell KB813': require('../../assets/images/Dell_perifericos/Dell_Wireless_Keyboard_KM636.png'),
  'Dell KB813 Wireless': require('../../assets/images/Dell_perifericos/Dell_Wireless_Keyboard_KM636.png'),
  'Dell Multi-Device Keyboard - KM7321W': require('../../assets/images/Dell_perifericos/Dell_Wireless_Keyboard_KM636.png'),
  'Dell KM717 Wireless Keyboard and Mouse': require('../../assets/images/Dell_perifericos/Dell_Wireless_Keyboard_KM636.png'),
  'Dell Pro Stereo Headset': require('../../assets/images/Dell_perifericos/Dell_Pro_Stereo_Headset.png'),
  'Dell UC150 USB Headset': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell Premium Active Noise-Cancelling Headset': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell USB Headset - AE2': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell Pro Wireless Headset': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell Stereo Headset - AE2': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell Vostro Headset': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell Bluetooth Headset': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell Headset - EH01': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell USB Headset - UC350': require('../../assets/images/Dell_perifericos/Dell_UC150_USB_Headset.png'),
  'Dell C5519Q': require('../../assets/images/Dell_perifericos/Dell_C5519Q.png'),
  'Dell 55 4K UHD Smart TV': require('../../assets/images/Dell_perifericos/Dell_55_4K_UHD_Smart_TV.png'),
  'Dell P4317Q': require('../../assets/images/Dell_perifericos/Dell_55_4K_UHD_Smart_TV.png'),
  'Dell S3221QS': require('../../assets/images/Dell_perifericos/Dell_55_4K_UHD_Smart_TV.png'),
  'Dell C1422H': require('../../assets/images/Dell_perifericos/Dell_55_4K_UHD_Smart_TV.png'),
  'Dell UltraSharp U4320Q': require('../../assets/images/Dell_perifericos/Dell_55_4K_UHD_Smart_TV.png'),
  'Dell S2421H': require('../../assets/images/Dell_perifericos/Dell_55_4K_UHD_Smart_TV.png'),
  'Dell UltraSharp U2720Q': require('../../assets/images/Dell_perifericos/Dell_55_4K_UHD_Smart_TV.png'),
  'Dell P2720D': require('../../assets/images/Dell_perifericos/Dell_55_4K_UHD_Smart_TV.png'),

  'Lenovo 300 USB Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo ThinkPad Basic USB Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_Basic_USB_Mouse.png'),
  'Lenovo 500 Wireless Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo ThinkPad Wireless Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo Laser Wireless Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo ThinkPad X1 Wireless Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo 400 USB Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo 600 Wireless Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo N70A Wireless Mouse': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo Wireless Mouse - N100': require('../../assets/images/Lenovo_perifericos/Lenovo_300_USB_Mouse.png'),
  'Lenovo ThinkPad Compact USB Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_Compact_USB_Keyboard.png'),
  'Lenovo Preferred Pro USB Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_Preferred_Pro_USB_Keyboard.png'),
  'Lenovo ThinkPad Bluetooth Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_Preferred_Pro_USB_Keyboard.png'),
  'Lenovo ThinkPad USB Keyboard with TrackPoint': require('../../assets/images/Lenovo_perifericos/Lenovo_Preferred_Pro_USB_Keyboard.png'),
  'Lenovo Legion K500 RGB Gaming Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_Preferred_Pro_USB_Keyboard.png'),
  'Lenovo 300 USB Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_Preferred_Pro_USB_Keyboard.png'),
  'Lenovo 500 Wireless Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_Preferred_Pro_USB_Keyboard.png'),
  'Lenovo Professional Wireless Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_Preferred_Pro_USB_Keyboard.png'),
  'Lenovo ThinkPad X1 Carbon Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_Preferred_Pro_USB_Keyboard.png'),
  'Lenovo 100 Wired Keyboard': require('../../assets/images/Lenovo_perifericos/Lenovo_100_Wired_Keyboard.png'),
  'Lenovo Stereo Headset': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo ThinkPad USB Headset': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo LP6 Wireless Headset': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo HD100 Bluetooth Headset': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo H301 Wired Headset': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo HT50 Wireless Headphones': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo H100 Wired Headset': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo ThinkPad X1 Headset': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo Wireless Headset - H600': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo HD300 Headset': require('../../assets/images/Lenovo_perifericos/Lenovo_ThinkPad_USB_Headset.png'),
  'Lenovo Smart TV 4K': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV X1': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV 55-inch': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV 65-inch': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV 50-inch': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV 43-inch': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV 32-inch': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV 28-inch': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV 70-inch': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),
  'Lenovo Smart TV QLED': require('../../assets/images/Lenovo_perifericos/Lenovo_Smart_TV_4K.png'),

  'Logitech MX Master 3': require('../../assets/images/Logitech_perifericos/Logitech_MX_Master_3.png'),
  'Logitech M720 Triathlon': require('../../assets/images/Logitech_perifericos/Logitech_MX_Master_3.png'),
  'Logitech MX Anywhere 3': require('../../assets/images/Logitech_perifericos/Logitech_MX_Master_3.png'),
  'Logitech G502 HERO': require('../../assets/images/Logitech_perifericos/Logitech_MX_Master_3.png'),
  'Logitech M185 Wireless Mouse': require('../../assets/images/Logitech_perifericos/Logitech_MX_Master_3.png'),
  'Logitech M590 Multi-Device Silent': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech MX Master 2S': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech MX Vertical': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech G Pro Wireless': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech M330 Silent Plus': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech K780': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech MX Keys': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech K380': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech G915 TKL': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech K400 Plus': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech G513 RGB': require('../../assets/images/Logitech_perifericos/Logitech_M590_Multi-Device_Silent.png'),
  'Logitech K270 Wireless Keyboard': require('../../assets/images/Logitech_perifericos/Logitech_K270_Wireless_Keyboard.png'),
  'Logitech K860 Ergonomic Keyboard': require('../../assets/images/Logitech_perifericos/Logitech_K270_Wireless_Keyboard.png'),
  'Logitech G610 Orion Brown': require('../../assets/images/Logitech_perifericos/Logitech_K270_Wireless_Keyboard.png'),
  'Logitech K145': require('../../assets/images/Logitech_perifericos/Logitech_K270_Wireless_Keyboard.png'),
  'Logitech H800 Bluetooth Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech G Pro X Gaming Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech H390 USB Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech H151 Wired Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech G433 Gaming Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech Zone Wired Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech H820e Wireless Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech H540 USB Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech G935 Wireless Gaming Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),
  'Logitech G332 Stereo Gaming Headset': require('../../assets/images/Logitech_perifericos/Logitech_H800_Bluetooth_Headset.png'),

  'JBL Quantum 800': require('../../assets/images/JBL_perifericos/JBL_Quantum_800.png'),
  'JBL Live 650BTNC': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),
  'JBL Tune 750BTNC': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),
  'JBL Tune 500BT': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),
  'JBL Endurance Peak II': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),
  'JBL Quantum 400': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),
  'JBL Quantum 600': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),
  'JBL Quantum One': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),
  'JBL Live Pro+ TWS': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),
  'JBL Reflect Flow': require('../../assets/images/JBL_perifericos/JBL_Live_650BTNC.png'),

  // 'HP Wireless Elite v2': require('../../assets/images/HP_perifericos/HP_Wireless_Elite_v2.png'),
  // 'HP K2500 Wireless Keyboard': require('../../assets/images/HP/HP_K2500_Wireless_Keyboard.png'),
  // 'HP 300 Wired Keyboard': require('../../assets/images/HP/HP_300_Wired_Keyboard.png'),
  // 'HP Premium Keyboard': require('../../assets/images/HP/HP_Premium_Keyboard.png'),
  // 'HP Pavilion Gaming Keyboard': require('../../assets/images/HP/HP_Pavilion_Gaming_Keyboard.png'),
  // 'HP 125 Wireless Keyboard': require('../../assets/images/HP/HP_125_Wireless_Keyboard.png'),
  // 'HP 100 Wired Keyboard': require('../../assets/images/HP/HP_100_Wired_Keyboard.png'),
  // 'HP Z Keyboard': require('../../assets/images/HP/HP_Z_Keyboard.png'),
  // 'HP Spectre x360 Keyboard': require('../../assets/images/HP/HP_Spectre_x360_Keyboard.png'),
  // 'HP 600 Wired Keyboard': require('../../assets/images/HP/HP_600_Wired_Keyboard.png'),
  // 'HP X1000 Wireless Headset': require('../../assets/images/HP/HP_X1000_Wireless_Headset.png'),
  // 'HP Pavilion Gaming Headset': require('../../assets/images/HP/HP_Pavilion_Gaming_Headset.png'),
  // 'HP Wired Headset': require('../../assets/images/HP/HP_Wired_Headset.png'),
  // 'HP Bluetooth Headset': require('../../assets/images/HP/HP_Bluetooth_Headset.png'),
  // 'HP USB Headset': require('../../assets/images/HP/HP_USB_Headset.png'),
  // 'HP 200 Wireless Headset': require('../../assets/images/HP/HP_200_Wireless_Headset.png'),
  // 'HP 400 Wired Headset': require('../../assets/images/HP/HP_400_Wired_Headset.png'),
  // 'HP 600 Wireless Headset': require('../../assets/images/HP/HP_600_Wireless_Headset.png'),
  // 'HP Elite Wireless Headset': require('../../assets/images/HP/HP_Elite_Wireless_Headset.png'),
  // 'HP Pavilion Wireless Headset': require('../../assets/images/HP/HP_Pavilion_Wireless_Headset.png'),
  // 'HP Smart TV 4K': require('../../assets/images/HP/HP_Smart_TV_4K.png'),
  // 'HP Smart TV 65-inch': require('../../assets/images/HP/HP_Smart_TV_65-inch.png'),
  // 'HP Smart TV 55-inch': require('../../assets/images/HP/HP_Smart_TV_55-inch.png'),
  // 'HP Smart TV 50-inch': require('../../assets/images/HP/HP_Smart_TV_50-inch.png'),
  // 'HP Smart TV 43-inch': require('../../assets/images/HP/HP_Smart_TV_43-inch.png'),
  // 'HP Smart TV 32-inch': require('../../assets/images/HP/HP_Smart_TV_32-inch.png'),
  // 'HP Smart TV 70-inch': require('../../assets/images/HP/HP_Smart_TV_70-inch.png'),
  // 'HP Smart TV 28-inch': require('../../assets/images/HP/HP_Smart_TV_28-inch.png'),
  // 'HP Smart TV QLED': require('../../assets/images/HP/HP_Smart_TV_QLED.png'),
  // 'HP Smart TV 8K': require('../../assets/images/HP/HP_Smart_TV_8K.png'),

  // 'Microsoft Arc Mouse': require('../../assets/images/Microsoft/Microsoft_Arc_Mouse.png'),
  // 'Microsoft Surface Mouse': require('../../assets/images/Microsoft/Microsoft_Surface_Mouse.png'),
  // 'Microsoft Sculpt Ergonomic Mouse': require('../../assets/images/Microsoft/Microsoft_Sculpt_Ergonomic_Mouse.png'),
  // 'Microsoft Wireless Mobile Mouse 4000': require('../../assets/images/Microsoft/Microsoft_Wireless_Mobile_Mouse_4000.png'),
  // 'Microsoft Bluetooth Mouse': require('../../assets/images/Microsoft/Microsoft_Bluetooth_Mouse.png'),
  // 'Microsoft Modern Mobile Mouse': require('../../assets/images/Microsoft/Microsoft_Modern_Mobile_Mouse.png'),
  // 'Microsoft Surface Precision Mouse': require('../../assets/images/Microsoft/Microsoft_Surface_Precision_Mouse.png'),
  // 'Microsoft Classic IntelliMouse': require('../../assets/images/Microsoft/Microsoft_Classic_IntelliMouse.png'),
  // 'Microsoft Designer Compact Mouse': require('../../assets/images/Microsoft/Microsoft_Designer_Compact_Mouse.png'),
  // 'Microsoft Pro IntelliMouse': require('../../assets/images/Microsoft/Microsoft_Pro_IntelliMouse.png'),
  // 'Microsoft Surface Keyboard': require('../../assets/images/Microsoft/Microsoft_Surface_Keyboard.png'),
  // 'Microsoft Sculpt Ergonomic Keyboard': require('../../assets/images/Microsoft/Microsoft_Sculpt_Ergonomic_Keyboard.png'),
  // 'Microsoft Wireless Keyboard 850': require('../../assets/images/Microsoft/Microsoft_Wireless_Keyboard_850.png'),
  // 'Microsoft Modern Keyboard': require('../../assets/images/Microsoft/Microsoft_Modern_Keyboard.png'),
  // 'Microsoft Natural Ergonomic Keyboard 4000': require('../../assets/images/Microsoft/Microsoft_Natural_Ergonomic_Keyboard_4000.png'),
  // 'Microsoft Surface Pro Keyboard': require('../../assets/images/Microsoft/Microsoft_Surface_Pro_Keyboard.png'),
  // 'Microsoft Bluetooth Keyboard': require('../../assets/images/Microsoft/Microsoft_Bluetooth_Keyboard.png'),
  // 'Microsoft Designer Compact Keyboard': require('../../assets/images/Microsoft/Microsoft_Designer_Compact_Keyboard.png'),
  // 'Microsoft All-in-One Media Keyboard': require('../../assets/images/Microsoft/Microsoft_All-in-One_Media_Keyboard.png'),
  // 'Microsoft Surface Headphones': require('../../assets/images/Microsoft/Microsoft_Surface_Headphones.png'),
  // 'Microsoft Modern USB Headset': require('../../assets/images/Microsoft/Microsoft_Modern_USB_Headset.png'),
  // 'Microsoft LifeChat LX-3000': require('../../assets/images/Microsoft/Microsoft_LifeChat_LX-3000.png'),
  // 'Microsoft Xbox Wireless Headset': require('../../assets/images/Microsoft/Microsoft_Xbox_Wireless_Headset.png'),
  // 'Microsoft Surface Earbuds': require('../../assets/images/Microsoft/Microsoft_Surface_Earbuds.png'),
  // 'Microsoft H5S-00002': require('../../assets/images/Microsoft/Microsoft_H5S-00002.png'),
  // 'Microsoft H7S-00001': require('../../assets/images/Microsoft/Microsoft_H7S-00001.png'),
  // 'Microsoft LifeChat LX-6000': require('../../assets/images/Microsoft/Microsoft_LifeChat_LX-6000.png'),
  // 'Microsoft Surface Headphones 2': require('../../assets/images/Microsoft/Microsoft_Surface_Headphones_2.png'),
  // 'Microsoft Headset - 3.5mm': require('../../assets/images/Microsoft/Microsoft_Headset_3.5mm.png'),

  'HP LaserJet Pro M404dn': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP LaserJet Pro MFP M428fdw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_MFP_M428fdw.png'),
  'HP Color LaserJet Pro MFP M479fdw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_MFP_M428fdw.png'),
  'HP OfficeJet Pro 9015e': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_MFP_M428fdw.png'),
  'HP LaserJet Enterprise M507dn': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_MFP_M428fdw.png'),
  'HP LaserJet Enterprise MFP M528dn': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_MFP_M428fdw.png'),
  'HP OfficeJet Pro 9025e': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_MFP_M428fdw.png'),
  'HP LaserJet Pro MFP M227fdw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP LaserJet Pro MFP M281fdw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP Color LaserJet Enterprise MFP M681dh': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP LaserJet Enterprise Flow MFP M830z': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP Color LaserJet Pro M255dw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP LaserJet Pro M15w': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP LaserJet Pro M203dw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP OfficeJet 200 Mobile Printer': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP PageWide Pro 477dw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP LaserJet Pro 200 M251nw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP LaserJet Pro MFP M130fn': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'HP Color LaserJet Pro M182nw': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),
  'Outro modelo': require('../../assets/images/HP_impressora/HP_LaserJet_Pro_M404dn.png'),

  'Canon imageCLASS MF269dw': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF269dw.png'),
  'Canon imageCLASS MF445dw': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon PIXMA TR8620a': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageCLASS MF644Cdw': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageCLASS MF743Cdw': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageRUNNER 1643iF': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon MAXIFY MB2720': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon PIXMA G7020': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageCLASS LBP6230dw': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageCLASS D570': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageCLASS MF424dw': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageCLASS MF3010': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageRUNNER 2530': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon PIXMA MG3620': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon Color imageCLASS MF810Cdn': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageRUNNER 1435iF': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageCLASS MF236n': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon imageCLASS MF269dw VP': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Canon PIXMA TS6320': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),
  'Outro modelo': require('../../assets/images/Canon_impressora/Canon_imageCLASS_MF445dw.png'),

};
