import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
    Box, Typography, TextField, Button, Select, MenuItem, FormControl, ListItemIcon, ListItemText, Paper, Grid, styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaLaptop, FaApple, FaWindows, FaLinux } from 'react-icons/fa';
import { MdMonitor, MdOutlineSmartphone } from 'react-icons/md';
import { FaTabletAlt } from "react-icons/fa";
import { SiDell, SiLenovo, SiAsus, SiSamsung, SiMacos } from 'react-icons/si';
import { modelosPorFabricante } from './Data_Ativo'; // Certifique-se de que este arquivo existe e está correto
import './styles.min.css';
import { FiPrinter } from "react-icons/fi";
import { FaMouse } from "react-icons/fa";

const CustomTextField = styled(TextField)(({ theme }) => ({
    // Adicione o estilo personalizado, se necessário
}));

const ComputerForm = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        nome_ativo: '',
        data_aquisicao: '',
        tipo_ativo: '',
        fabricante: '',
        modelo: '',
        numero_serie: '',
        reg_model: '',
        especificacoes_tecnicas: '',
        possui_garantia: false,
        detalhes_garantia: '',
        validade_garantia: '',
        valor_ativo: '',
        tipo_periferico: '',
        status: 'disponivel',
        sistema_operacional: '',
        responsavel: '',
        localidade: '',
        data_entrega_responsavel: '',
        possui_antivirus: false,
        observacoes: '',
    });
    const [modelos, setModelos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ severity: '', message: '', visible: false });



    const handleTipoAtivoChange = (event) => {
        const novoTipoAtivo = event.target.value;
        setFormData({
            ...formData,
            tipo_ativo: novoTipoAtivo,
            fabricante: '',
            modelo: ''
        });
        setModelos([]);
    };

    const handleFabricanteChange = (event) => {
        const novoFabricante = event.target.value;
        setFormData({
            ...formData,
            fabricante: novoFabricante,
            modelo: ''
        });
        setModelos(modelosPorFabricante[formData.tipo_ativo]?.[novoFabricante] || []);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ severity: '', message: '', visible: false });
        const token = localStorage.getItem('token');

        const dataToSend = { ...formData };

        if (!dataToSend.possui_garantia) {
            dataToSend.detalhes_garantia = null;
            dataToSend.validade_garantia = null;
            dataToSend.valor_ativo = null;
            dataToSend.tipo_periferico = null;
        }
        if (!dataToSend.possui_antivirus) {
            dataToSend.possui_antivirus = false;
        }
        if (dataToSend.status !== 'em uso') {
            dataToSend.responsavel = null;
            dataToSend.localidade = null;
            dataToSend.data_entrega_responsavel = null;
        }
        if (!dataToSend.observacoes) {
            dataToSend.observacoes = null;
        }

        try {
            await axios.post('/api/cadastro-ativos/', dataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setAlert({ severity: 'success', message: t('success_message'), visible: true });


            setFormData({
                nome_ativo: '',
                data_aquisicao: '',
                tipo_ativo: '',
                fabricante: '',
                modelo: '',
                numero_serie: '',
                reg_model: '',
                especificacoes_tecnicas: '',
                possui_garantia: false,
                detalhes_garantia: '',
                validade_garantia: '',
                valor_ativo: '',
                tipo_periferico: '',
                status: 'disponivel',
                sistema_operacional: '',
                responsavel: '',
                localidade: '',
                data_entrega_responsavel: '',
                possui_antivirus: false,
                observacoes: '',
            });
            setModelos([]);
        } catch (error) {
            setAlert({ severity: 'error', message: t('error_message'), visible: true });
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ ...alert, visible: false });
    };

    useEffect(() => {
        document.title = t('computer_form_title') + ' | PinnSystem';
    }, [t]);

    return (
        <Box sx={{ padding: 2, backgroundColor: '#f7f7f7', minHeight: '100vh' }}>
            <Typography variant="h4" gutterBottom>
                <Box component="span" sx={{ color: 'gray' }}>{t('asset_management')}/</Box>
                <Box component="span">{t('register_asset')}</Box>
            </Typography>
            <Paper sx={{ padding: 3, marginTop: 1, boxShadow: 'none' }}>
                <form className="computer-form" onSubmit={handleSubmit}>
                    <Typography variant="h4" gutterBottom className="form-section-title">{t('Cadastro de informações')}</Typography>
                    <Typography variant="h6" color="#007bff;" gutterBottom align="left">{t('Cadastre as principais informações com cuidado')}</Typography>
                    <Grid container spacing={1}>
                        <label className="custom-label">
                            <span>
                                Nome do Ativo<span className="required">*</span>
                            </span>
                            <input
                                type="text"
                                name="nome_ativo"
                                value={formData.nome_ativo}
                                onChange={handleChange}
                                required
                                className="custom-input"
                            />
                        </label>


                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <CustomTextField
                                    label={
                                        <Box display="flex" alignItems="center">
                                            {t('Data de aquisição')}
                                            <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                        </Box>
                                    }
                                    name="data_aquisicao"
                                    type="date"
                                    value={formData.data_aquisicao}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography color="black">Selecione o tipo do Ativo</Typography>
                                    <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                </Box>
                                <Select
                                    name="tipo_ativo"
                                    value={formData.tipo_ativo}
                                    onChange={handleTipoAtivoChange}
                                    required
                                    displayEmpty
                                    sx={{ backgroundColor: '#ffff', borderRadius: 1 }}
                                >
                                    <MenuItem disabled value="">
                                        <em>Selecione uma opção</em>
                                    </MenuItem>
                                    <MenuItem value="Notebook">
                                        <ListItemIcon>
                                            <FaLaptop />
                                        </ListItemIcon>
                                        <ListItemText primary="Notebook" />
                                    </MenuItem>
                                    <MenuItem value="Monitor">
                                        <ListItemIcon>
                                            <MdMonitor />
                                        </ListItemIcon>
                                        <ListItemText primary="Monitor" />
                                    </MenuItem>
                                    <MenuItem value="Smartphone">
                                        <ListItemIcon>
                                            <MdOutlineSmartphone />
                                        </ListItemIcon>
                                        <ListItemText primary="Smartphone" />
                                    </MenuItem>
                                    <MenuItem value="Tablet">
                                        <ListItemIcon>
                                            <FaTabletAlt />
                                        </ListItemIcon>
                                        <ListItemText primary="Tablet" />
                                    </MenuItem>
                                    <MenuItem value="Impressora">
                                        <ListItemIcon>
                                            <FiPrinter />
                                        </ListItemIcon>
                                        <ListItemText primary="Impressora" />
                                    </MenuItem>

                                    <MenuItem value="Periféricos">
                                        <ListItemIcon>
                                            <FaMouse />
                                        </ListItemIcon>
                                        <ListItemText primary="Periféricos" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            {formData.tipo_ativo === 'Periféricos' && (
                                <FormControl fullWidth margin="normal">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography color="textPrimary">Tipo de Periférico</Typography>
                                        <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                    </Box>
                                    <Select
                                        name="tipo_periferico"
                                        value={formData.tipo_periferico}
                                        onChange={handleChange}
                                        required
                                        sx={{ backgroundColor: '#ffff', borderRadius: 1 }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Escolha uma opção</em>
                                        </MenuItem>
                                        <MenuItem value="Teclado">
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <ListItemText primary="Teclado" />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="Mouse">
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <ListItemText primary="Mouse" />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="Headphone">
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <ListItemText primary="Headphone" />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="Televisão">
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <ListItemText primary="Televisão" />
                                            </Box>
                                        </MenuItem>
                                        {/* Adicione mais opções conforme necessário */}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>



                        {formData.tipo_ativo && (
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography color="black">Selecione o Fabricante</Typography>
                                        <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                    </Box>
                                    <Select
                                        name="fabricante"
                                        value={formData.fabricante}
                                        onChange={handleFabricanteChange}
                                        required
                                        displayEmpty
                                        sx={{ backgroundColor: '#ffff', borderRadius: 1 }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Selecione uma opção</em>
                                        </MenuItem>
                                        {Object.keys(modelosPorFabricante[formData.tipo_ativo] || {}).map(fabricante => (
                                            <MenuItem key={fabricante} value={fabricante}>
                                                <ListItemText primary={fabricante} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {formData.fabricante && (
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography color="black">Selecione o Modelo</Typography>
                                        <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                    </Box>
                                    <Select
                                        name="modelo"
                                        value={formData.modelo}
                                        onChange={handleChange}
                                        required
                                        displayEmpty
                                        sx={{ backgroundColor: '#ffff', borderRadius: 1 }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Selecione uma opção</em>
                                        </MenuItem>
                                        {modelos.map(modelo => (
                                            <MenuItem key={modelo} value={modelo}>
                                                <ListItemText primary={modelo} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <label className="custom-label">
                            <span>
                                Número de série<span className="required">*</span>
                            </span>
                            <input
                                type="text"
                                name="numero_serie"
                                value={formData.numero_serie}
                                onChange={handleChange}
                                required
                                className="custom-input"
                            />
                        </label>

                        {formData.tipo_ativo === 'Notebook' && (
                            <>
                                <label className="custom-label">
                                    Reg Model
                                    <input
                                        type="text"
                                        name="reg_model"
                                        value={formData.reg_model}
                                        onChange={handleChange}
                                        required
                                        className="custom-input"
                                    />
                                </label>


                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="normal">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography color="textPrimary">Sistema Operacional</Typography>
                                            <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                        </Box>
                                        <Select
                                            name="sistema_operacional"
                                            value={formData.sistema_operacional}
                                            onChange={handleChange}
                                            required
                                            sx={{ backgroundColor: '#ffff', borderRadius: 1 }}
                                        >
                                            <MenuItem disabled value="">
                                                <em>Selecione uma opção</em>
                                            </MenuItem>
                                            <MenuItem value="Windows">
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                                        <FaWindows />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Windows" />
                                                </Box>
                                            </MenuItem>
                                            <MenuItem value="Linux">
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                                        <FaLinux />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Linux" />
                                                </Box>
                                            </MenuItem>
                                            <MenuItem value="MacOS">
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                                        <SiMacos />
                                                    </ListItemIcon>
                                                    <ListItemText primary="macOS" />
                                                </Box>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        <label className="custom-label">
                            Especificações técnicas
                            <input
                                type="text"
                                name="especificacoes_tecnicas"
                                value={formData.especificacoes_tecnicas}
                                onChange={handleChange}
                                required
                                className="custom-input"
                            />
                        </label>

                        <label className="custom-label">
                            <span>
                                Valor do Ativo<span className="required">*</span>
                            </span>
                            <input
                                type="text"
                                name="valor_ativo"
                                value={formData.valor_ativo}
                                onChange={handleChange}
                                required
                                className="custom-input"
                            />
                        </label>

                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography color="textPrimary">Status do Ativo</Typography>
                                    <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                </Box>
                                <Select
                                    name="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                    required
                                    sx={{ backgroundColor: '#ffff', borderRadius: 1 }}

                                >
                                    <MenuItem disabled value="">
                                        <em>Selecione uma opção</em>
                                    </MenuItem>
                                    <MenuItem value="disponivel"><ListItemText primary="Disponivel" /></MenuItem>
                                    <MenuItem value="em uso"><ListItemText primary="Em uso" /></MenuItem>
                                    <MenuItem value="em manutencao"><ListItemText primary="Em manunteção" /></MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {formData.status === 'em uso' && (
                            <>
                                <label className="custom-label">
                                    <span>
                                        Responsável pelo ativo<span className="required">*</span>
                                    </span>
                                    <input
                                        type="text"
                                        name="responsavel"
                                        value={formData.responsavel}
                                        onChange={handleChange}
                                        required
                                        className="custom-input" /* Adicione esta classe */
                                    />
                                </label>
                                <label className="custom-label">
                                    <span>
                                        Localidade atual<span className="required">*</span>
                                    </span>
                                    <input
                                        type="text"
                                        name="localidade"
                                        value={formData.localidade}
                                        onChange={handleChange}
                                        required
                                        className="custom-input" /* Adicione esta classe */
                                    />
                                </label>

                                <Grid item xs={12}>
                                    <CustomTextField
                                        label={t('delivery_date')}
                                        name="data_entrega_responsavel"
                                        type="date"
                                        value={formData.data_entrega_responsavel}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography color="textPrimary">Possui Garantia</Typography>
                                    <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                </Box>
                                <Select
                                    name="possui_garantia"
                                    value={formData.possui_garantia}
                                    onChange={handleChange}
                                    required
                                    sx={{ backgroundColor: '#ffff', borderRadius: 1 }}
                                >
                                    <MenuItem disabled value="">
                                        <em>Selecione uma opção</em>
                                    </MenuItem>
                                    <MenuItem value={true}>Sim</MenuItem>
                                    <MenuItem value={false}>Não</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {formData.possui_garantia === true && (
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography color="textPrimary">Detalhes da garantia</Typography>
                                        <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                    </Box>
                                    <Select
                                        name="detalhes_garantia"
                                        value={formData.detalhes_garantia}
                                        onChange={handleChange}
                                        required
                                        sx={{ backgroundColor: '#ffff', borderRadius: 1 }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Selecione uma opção</em>
                                        </MenuItem>
                                        <MenuItem value="Básica">Básica</MenuItem>
                                        <MenuItem value="Premium">Premium</MenuItem>
                                        <MenuItem value="Personalizada">Personalizada</MenuItem>
                                    </Select>
                                </FormControl>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label={t('Data de expiração')}
                                        name="validade_garantia"
                                        type="date"
                                        value={formData.validade_garantia}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>

                        )}

                        {formData.status === 'em uso' && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="normal">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography color="textPrimary">Possui Antivírus</Typography>
                                            <Typography color="error" sx={{ ml: 0.5 }}>*</Typography>
                                        </Box>
                                        <Select
                                            name="possui_antivirus"
                                            value={formData.possui_antivirus}
                                            onChange={handleChange}
                                            required
                                            sx={{ backgroundColor: '#ffff', borderRadius: 1 }}
                                        >
                                            <MenuItem value={true}>Sim</MenuItem>
                                            <MenuItem value={false}>Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <CustomTextField
                                label={t('Observações')}
                                name="observacoes"
                                value={formData.observacoes}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                multiline
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={3} display="flex" justifyContent="flex-end">
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            {loading ? t('submitting') : t('Cadastrar Ativo')}
                        </Button>
                    </Box>
                </form>
            </Paper>
            <Snackbar
                open={alert.visible}
                autoHideDuration={1000}
                onClose={handleClose}
                style={{ top: '-500px', left: '50%', transform: 'translateX(-50%)' }}
            >
                <Alert onClose={handleClose} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>

        </Box>
    );
};

export default ComputerForm;